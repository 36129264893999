import accountingTransactions from './accountingTransactions';
import accounts from './accounts';
import accountBalances from './accountBalances';
import accountTypes from './accountTypes';
import aiSearch from './aiSearch';
import attachments from './attachments';
import avBillingInfos from './avBillingInfos';
import batchOperations from './batchOperations';
import bills from './bills';
import billingInfos from './billingInfos';
import categories from './categories';
import chatGptPrompts from './chatGptPrompts';
import colors from './colors';
import currencyRates from './currencyRates';
import customFields from './customFields';
import downloads from './downloads';
import emailTemplates from './emailTemplates';
import evaluations from './evaluations';
import evaluationSets from './evaluationSets';
import files from './files';
import filters from './filters';
import financeAddresses from './financeAddresses';
import generalLedgers from './generalLedgers';
import images from './images';
import items from './items';
import itemTypes from './itemTypes';
import itemCategories from './itemCategories';
import invoices from './invoices';
import journalRecords from './journalRecords';
import journalRecordImports from './journalRecordImports';
import journalRecordImportRows from './journalRecordImportRows';
import libraries from './libraries';
import lifestyles from './lifestyles';
import materials from './materials';
import menu from './menu';
import messages from './messages';
import operations from './operations';
import pages from './pages';
import payments from './payments';
import permissions from './permissions';
import previewModels from './previewModels';
import products from './products';
import profiles from './profiles';
import projects from './projects';
import projectMembers from './projectMembers';
import projectMemberInvites from './projectMemberInvites';
import projectNotifications from './projectNotifications';
import reports from './reports';
import reportSections from './reportSections';
import results from './results';
import revenueShareItems from './revenueShareItems';
import settings from './settings';
import selectedLifestyles from './selectedLifestyles';
import selectedMaterials from './selectedMaterials';
import selectedProducts from './selectedProducts';
import sourceFiles from './sourceFiles';
import smartContracts from './smartContracts';
import supersetReports from './supersetReports';
import statuses from './statuses';
import surveys from './surveys';
import tags from './tags';
import tasks from './tasks';
import teamInvitations from './teamInvitations';
import taskMembers from './taskMembers';
import taskMemberInvites from './taskMemberInvites';
import taskNotifications from './taskNotifications';
import transactionTags from './transactionTags';
import teams from './teams';
import teamsUsers from './teamsUsers';
import todoItems from './todoItems';
import transactions from './transactions';
import ueStreamings from './ueStreamings';
import users from './users';
import whiteboards from './whiteboards';

export default {
  accountingTransactions,
  accounts,
  accountBalances,
  accountTypes,
  aiSearch,
  attachments,
  avBillingInfos,
  batchOperations,
  bills,
  billingInfos,
  categories,
  chatGptPrompts,
  colors,
  currencyRates,
  customFields,
  downloads,
  emailTemplates,
  evaluations,
  evaluationSets,
  files,
  filters,
  financeAddresses,
  generalLedgers,
  images,
  items,
  itemTypes,
  itemCategories,
  invoices,
  journalRecords,
  journalRecordImports,
  journalRecordImportRows,
  libraries,
  lifestyles,
  materials,
  menu,
  messages,
  operations,
  pages,
  payments,
  permissions,
  previewModels,
  products,
  profiles,
  projects,
  projectMembers,
  projectMemberInvites,
  projectNotifications,
  reports,
  reportSections,
  results,
  revenueShareItems,
  settings,
  selectedLifestyles,
  selectedMaterials,
  selectedProducts,
  sourceFiles,
  smartContracts,
  supersetReports,
  statuses,
  surveys,
  tags,
  tasks,
  teamInvitations,
  taskMembers,
  taskMemberInvites,
  taskNotifications,
  teams,
  teamsUsers,
  todoItems,
  transactions,
  transactionTags,
  ueStreamings,
  users,
  whiteboards
};
