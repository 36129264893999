import { useQuery } from 'react-query';

import { LocalForage } from '../../../../../../utils/LocalForage';

import {
  FetchItemCacheKey,
  FetchItemGqlQuery,
  ID,
  NanoID,
  UUID
} from '../../../../../../types';

import { fetchFinItem } from '../baseActions/fetchFinItem';

import { parseRequestError } from '../../../../../../utils/parseRequestError';

import { ShowQueryErrorType, ShowQueryOpts } from '../useShowQuery';

interface ShowQueryOptions<ResponseType> {
  cacheKey: FetchItemCacheKey;
  itemKey: string;
  query: FetchItemGqlQuery;
  uuid: UUID | NanoID | ID;
  placeholderData?: ResponseType;
  options?: ShowQueryOpts<ResponseType>;
}

function useFinShowQuery<ResponseType, ItemType>({
  cacheKey,
  itemKey,
  query,
  uuid,
  placeholderData,
  options = {}
}: ShowQueryOptions<ResponseType>) {
  const localForageCacheKey = `${cacheKey}-${uuid}`;

  const {
    data: localForagePlaceholderData,
    isFetched: placeholderDataFetched
  } = useQuery<ResponseType | null>(
    `${localForageCacheKey}-placeholder`,
    () => LocalForage.getItem<ResponseType>(localForageCacheKey),
    {
      enabled: options.enabledPlaceholder,
      onSuccess: (data) => options.onSuccess?.(data)
    }
  );

  const {
    data,
    isFetched,
    isLoading,
    isFetching,
    error,
    isPlaceholderData,
    refetch
  } = useQuery<ResponseType, ShowQueryErrorType>(
    [cacheKey, uuid],
    () =>
      fetchFinItem({
        query,
        uuid
      }),
    {
      retry: options.retry,
      cacheTime: options.cacheTime,
      staleTime: options.staleTime,
      enabled: options.enabled || placeholderDataFetched,
      placeholderData:
        placeholderData || localForagePlaceholderData || undefined,
      onSettled: options.onSettled,
      onSuccess: (data) => {
        options.onSuccess?.(data);
        return LocalForage.setItem<ResponseType>(localForageCacheKey, data);
      },
      onError: options.onError
    }
  );

  const item: ItemType | null = data?.[itemKey] || null;

  return {
    item,
    itemErrorData: error,
    itemError: parseRequestError(error),
    itemFetched: isFetched,
    itemFetching: isFetching,
    itemLoading: isLoading,
    itemIsPlaceholderData: isPlaceholderData,
    refetchItem: refetch
  };
}

export default useFinShowQuery;
