import { FetchTaskMemberInvitesSortTypes } from './taskMemberInvitesTypes';

export const INITIAL_TASK_MEMBER_INVITES_FILTERS = {};
export const INITIAL_TASK_MEMBER_INVITES_PAGE = 1;
export const INITIAL_TASK_MEMBER_INVITES_SORT = [
  FetchTaskMemberInvitesSortTypes.CREATED_AT_DESC
];
export const INITIAL_TASK_MEMBER_INVITES_LIMIT = 24;

export const enum TaskMemberInvitesPermissions {
  READ_TASK_MEMBER_INVITES_TABLE_USER_FIELD = 'read_task_member_invites_table_user_field',
  READ_TASK_MEMBER_INVITES_TABLE_CREATED_AT_FIELD = 'read_task_member_invites_table_created_at_field',
  READ_TASK_MEMBER_INVITES_INDEX_PAGE = 'read_task_member_invites_index_page',
  READ_ACCEPT_TASK_MEMBER_INVITE_BUTTON = 'read_accept_task_member_invite_button',
  READ_CHECK_IN_TASK_MEMBER_INVITE_BUTTON = 'read_check_in_task_member_invite_button',
  READ_CHECK_IN_WITHOUT_FOLLOWERS_TASK_MEMBER_INVITE_BUTTON = 'read_check_in_without_followers_task_member_invite_button',
  READ_CHECK_IN_SMART_CONTRACT_SHARE_TASK_MEMBER_INVITE_BUTTON = 'read_check_in_smart_contract_share_task_member_invite_button',
  READ_CHECK_IN_WITHOUT_FOLLOWERS_SMART_CONTRACT_SHARE_TASK_MEMBER_INVITE_BUTTON = 'read_check_in_without_followers_smart_contract_share_task_member_invite_button',
  READ_DECLINE_TASK_MEMBER_INVITE_BUTTON = 'read_decline_task_member_invite_button',
  READ_ACCEPT_SELECTED_TASK_MEMBER_INVITES_BUTTON = 'read_accept_selected_task_member_invites_button',
  READ_CHECK_IN_SELECTED_TASK_MEMBER_INVITES_BUTTON = 'read_check_in_selected_task_member_invites_button',
  READ_DECLINE_SELECTED_TASK_MEMBER_INVITES_BUTTON = 'read_decline_selected_task_member_invites_button',
  READ_ACCEPT_ALL_TASK_MEMBER_INVITES_BUTTON = 'read_accept_all_task_member_invites_button',
  READ_CHECK_IN_ALL_TASK_MEMBER_INVITES_BUTTON = 'read_check_in_all_task_member_invites_button',
  READ_CHECK_IN_ALL_WITHOUT_FOLLOWS_TASK_MEMBER_INVITES_BUTTON = 'read_check_in_all_without_follows_task_member_invites_button',
  READ_DECLINE_ALL_TASK_MEMBER_INVITES_BUTTON = 'read_decline_all_task_member_invites_button',
  READ_TASK_MEMBER_INVITES_COMPACT_INDEX_PAGE = 'read_task_member_invites_compact_index_page'
}
