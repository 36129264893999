export default {
  plural: 'Invitations',
  accept: 'Accept',
  acceptAll: 'Accept all',
  acceptSelected: 'Accept selected',
  checkInAll: 'Check in all',
  checkInAllWithoutFollowers: 'Check in all without followers',
  decline: 'Decline',
  declineAll: 'Decline all',
  declineSelected: 'Decline selected',
  invited: 'Invited',
  invitedBy: 'Invited by',
  invitedDate: 'Invited date',
  search: 'Search invitations by task name',
  tasksInvitations: 'Task invitations',
  areTouSureYouWantToAcceptAllTaskInvitationsThisActionCannotBeUndone:
    'Are you sure you want to accept all task invitations? This action cannot be undone.',
  areTouSureYouWantToDeclineAllTaskInvitationsThisActionCannotBeUndone:
    'Are you sure you want to decline all task invitations? This action cannot be undone.',
  areYouSureYouWantToDeclineTaskInvitationThisActionCannotBeUndone:
    'Are you sure you want to decline task invitation? This action cannot be undone.',
  areYouSureYouWantToDeleteTaskInvitationThisActionCannotBeUndone:
    'Are you sure you want to delete task invitation? This action cannot be undone.',
  removeInvitation: 'Remove invitation'
};
