export default {
  action: {
    required: 'Please enter an action'
  },
  add: {
    lessThanDebt: 'Value must be less than debt.',
    allocatedLessThanUnallocated:
      'Total allocated amount must be less than project unallocated sum.'
  },
  amount: {
    minAmountPayoneerTwenty: 'For Payoneer amount must be more than 20',
    nonNegative: 'Amount must be non-negative number',
    amountNoMoreThanYourAccount:
      'The amount must be equal or less than the balance.'
  },
  dueDate: {
    inFuture: 'Due date must be in the future',
    invalid: 'Due date is invalid or not set',
    noEarlierInvoiceIssue:
      'Due date should be no earlier than the date of invoice issue',
    cantBeBlank: "Due date can't be blank"
  },
  email: {
    required: 'Please enter an email',
    valid: 'Please enter valid email'
  },
  splitPartPercents: {
    required: 'Please enter split part percents'
  },
  estimateCost: {
    nonNegative: 'Estimate cost must be non-negative number'
  },
  filterName: {
    required: 'Please enter filter name'
  },
  fullName: {
    required: 'Please enter full name'
  },
  name: {
    required: 'Please enter a name'
  },
  namesHaveToBeUnique: 'Names have to be unique',
  surname: {
    required: 'Please enter a last name'
  },
  positionId: {
    required: 'Please select role'
  },
  city: {
    required: 'Please select city'
  },
  phone: {
    required: 'Please enter a phone',
    valid: 'Please enter valid phone'
  },
  client: {
    required: 'Please select client'
  },
  owner: {
    required: 'Please select owner'
  },
  company: {
    required: 'Please select company'
  },
  companyName: {
    required: 'Please enter company name'
  },
  projectName: {
    required: 'Please enter project name'
  },
  password: {
    doNotMatch: 'Passwords do not match',
    required: 'Please enter password',
    valid:
      'Please enter valid password. It should contain at least 6 characters'
  },
  policy: {
    required: 'You must accept the Payment, Refund, Privacy policy'
  },
  regPolicy: {
    required: 'You must accept the Privacy policy and Service agreement'
  },
  required: 'This field is required.',
  requiredLowerCase: 'required',
  share: {
    required: 'Please select share'
  },
  taskName: {
    required: 'Please enter task name'
  },
  hours: {
    nonNegative: 'Hours must be non-negative number',
    lessEight: 'Hours must be less than eight',
    required: 'Please enter hours or minutes'
  },
  minutes: {
    nonNegative: 'Minutes must be non-negative number',
    lessSixty: 'Minutes must be less than sixty'
  },
  message: {
    required: 'Please enter text or add attachments'
  },
  feedBackMessage: {
    required: 'Please enter your message'
  },
  defaultProjectPrice: {
    nonNegative: 'Default project price must be non-negative number'
  },
  defaultTaskPrice: {
    nonNegative: 'Default task price must be non-negative number'
  },
  dpi: {
    nonNegative: 'DPI must be non-negative number'
  },
  height: {
    nonNegative: 'Height must be non-negative number'
  },
  width: {
    nonNegative: 'Width must be non-negative number'
  },
  itemCategory: {
    required: 'Please select category'
  },
  price: {
    min: 'The price cannot be less than 0',
    required: 'Please enter price'
  },
  percent: {
    lessThanOneHundred: 'Percentage must be less than one hundred',
    nonNegative: 'Percentage must be non-negative number'
  },
  noDraftSmartContract: 'No draft smart contract',
  user: {
    required: 'Please select user'
  },
  mustBePositive: 'Value must be positive',
  unavailableValue: 'Value not available',
  pleaseSelectUserOrCreateCompanyRecord:
    'Please select user or create company record',
  tagName: {
    required: 'Please enter tag name'
  },
  time: {
    from10To19: 'Please enter time from 10:00 to 19:00'
  },
  toDate: {
    laterThanFrom: 'Must be later than From'
  },
  fromDate: {
    earlierThanTo: 'Must be earlier than To'
  }
};
