import React, { useState } from 'react';
import Link from 'next/link';

import { ClassName, GADataType, I18nText } from '../../../types';
import { IconsEnum } from '../../../assets/icons/types';

import { CompactNumberHelper } from '../../CompactNumberHelper';
import { Icon } from '../../../helpers/Icon';
import { Tooltip } from '../../tooltips/Tooltip';
import { TooltipSingletonTarget } from '../../tooltips/TooltipSingletonTarget';
import { Translate } from '../../Translate';

import { TooltipPlacement } from '../../tooltips/tooltipsConstants';

type NextPureTooltipIconLinkHelperOnClick = (
  e: React.MouseEvent<HTMLAnchorElement>
) => void;

export interface NextPureTooltipIconLinkHelperDefaultProps {
  badge?: number;
  className?: ClassName;
  divClassName?: ClassName;
  href: string;
  iconClassName?: ClassName;
  onClick?: NextPureTooltipIconLinkHelperOnClick;
  onMouseEnter?: () => void;
  prefetch?: boolean;
  rel?: string;
  tooltipI18nText: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
  tooltipClassName?: ClassName;
}

export interface NextPureTooltipIconLinkHelperIconProps {
  icon?: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text?: never;
}

interface NextPureTooltipIconLinkHelperWithI18nProps {
  icon?: IconsEnum | null;
  i18nTextClassName?: ClassName;
  i18nText: I18nText;
  text?: never;
}

interface NextPureTooltipIconLinkHelperWithTextProps {
  icon?: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

type NextPureTooltipIconLinkHelperProps =
  NextPureTooltipIconLinkHelperDefaultProps &
    GADataType &
    (
      | NextPureTooltipIconLinkHelperIconProps
      | NextPureTooltipIconLinkHelperWithI18nProps
      | NextPureTooltipIconLinkHelperWithTextProps
    );

function NextPureTooltipIconLinkHelper({
  badge,
  className,
  dataGa,
  divClassName,
  href,
  i18nText,
  i18nTextClassName,
  icon = null,
  iconClassName,
  onClick,
  onMouseEnter,
  prefetch,
  rel,
  text,
  tooltipClassName,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: NextPureTooltipIconLinkHelperProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLAnchorElement | null>(null);

  return (
    <Link
      className={className}
      data-ga={dataGa}
      href={href}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      prefetch={prefetch}
      ref={setReferenceTooltipElement}
      rel={rel}
    >
      <div className={divClassName || (badge > 0 ? 'relative' : 'inline')}>
        {icon ? <Icon className={iconClassName} icon={icon} /> : null}
        {badge > 0 ? (
          <CompactNumberHelper
            className="absolute -top-2.5 -right-2.5 text-2xs text-center leading-4 h-4 min-w-4 px-0.5 rounded-full text-white bg-red-600 font-bold font-mono"
            hideZeroValue
            value={badge}
          />
        ) : null}
        {icon && i18nText ? ' ' : null}
        {i18nText && i18nTextClassName ? (
          <span className={i18nTextClassName}>
            <Translate id={i18nText} />
          </span>
        ) : null}
        {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
        {text}
        {tooltipSingleton ? (
          <TooltipSingletonTarget
            referenceElement={referenceTooltipElement}
            tooltipI18nText={tooltipI18nText}
          />
        ) : (
          <Tooltip
            withArrow
            referenceElement={referenceTooltipElement}
            tooltipI18nText={tooltipI18nText}
            placement={tooltipPlacement}
            className={tooltipClassName}
          />
        )}
      </div>
    </Link>
  );
}

export default NextPureTooltipIconLinkHelper;
