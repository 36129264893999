import startsWith from 'lodash/startsWith';

import { PrimaryNavTabsEnum } from '../../types';
import { TeamNanoID } from '../../../../../main/teams/teamsTypes';
import { UserNanoID } from '../../../../../main/users/usersTypes';

import { AvBillingInfoPath } from '../../../../../main/avBillingInfos/AvBillingInfoPath';
import { BillPath } from '../../../../../main/bills/BillPath';
import { CategoryPath } from '../../../../../main/categories/CategoryPath';
import { ChatGptPromptPath } from '../../../../../main/chatGptPrompts/ChatGptPromptPath';
import { DefaultSmartContractRulePath } from '../../../../../main/defaultSmartContractRules/defaultSmartContractRulePath';
import { EmailTemplatePath } from '../../../../../main/emailTemplates/EmailTemplatePath';
import { InvoicePath } from '../../../../../main/invoices/InvoicePath';
import { ItemCategoryPath } from '../../../../../main/itemCategories/ItemCategoryPath';
import { ItemTypePath } from '../../../../../main/itemTypes/ItemTypePath';
import { JournalRecordImportRowsPath } from '../../../../../main/journalRecordImportRows/JournalRecordImportRowsPath';
import { JournalRecordImportsPath } from '../../../../../main/journalRecordImports/JournalRecordImportsPath';
import { JournalRecordPath } from '../../../../../main/journalRecords/JournalRecordPath';
import { LifestylePath } from '../../../../../main/lifestyles/LifestylePath';
import { MessagePath } from '../../../../../main/messages/MessagePath';
import { PagePath } from '../../../../../main/pages/PagePath';
import { PaymentPath } from '../../../../../main/payments/PaymentPath';
import { PermissionPath } from '../../../../../main/permissions/PermissionPath';
import { ProductPath } from '../../../../../main/products/ProductPath';
import { ProjectPath } from '../../../../../main/projects/ProjectPath';
import { ReportPath } from '../../../../../main/reports/ReportPath';
import { ReportSectionPath } from '../../../../../main/reportSections/ReportSectionPath';
import { RolePath } from '../../../../../main/roles/RolePath';
import { SectionPath } from '../../../../../main/sections/SectionPath';
import { SentMessagePath } from '../../../../../main/sentMessages/SentMessagePath';
import { StockPath } from '../../../../../main/stock/StockPath';
import { SupersetReportPath } from '../../../../../main/supersetReports/SupersetReportPath';
import { TaskPath } from '../../../../../main/tasks/TaskPath';
import { TeamPath } from '../../../../../main/teams/TeamPath';
import { UserPath } from '../../../../../main/users/UserPath';
import { InvoiceProcessingJobsPath } from '../../../../../main/invoiceProcessingJobs/InvoiceProcessingJobsPath';
import { MaterialPath } from '../../../../../main/materials/MaterialPath';

interface CurrentPathTabOptions {
  pathname: string;
  companyViewCompanyNanoId?: TeamNanoID;
  companyViewUserNanoId?: UserNanoID;
}

function getCurrentPathTab({
  pathname,
  companyViewCompanyNanoId,
  companyViewUserNanoId
}: CurrentPathTabOptions): PrimaryNavTabsEnum | null {
  // Company User Workspace Tabs
  if (
    companyViewCompanyNanoId &&
    companyViewUserNanoId &&
    (startsWith(
      pathname,
      TeamPath.companyUserProjects(
        companyViewCompanyNanoId,
        companyViewUserNanoId
      )
    ) ||
      startsWith(
        pathname,
        TeamPath.companyUserTasks(
          companyViewCompanyNanoId,
          companyViewUserNanoId
        )
      ) ||
      startsWith(
        pathname,
        TeamPath.companyUserMessages(
          companyViewCompanyNanoId,
          companyViewUserNanoId
        )
      ))
  ) {
    return PrimaryNavTabsEnum.COMPANY_VIEW_WORKSPACE;
  }

  // Company View Tabs
  if (
    companyViewCompanyNanoId &&
    (startsWith(pathname, TeamPath.companyProfile(companyViewCompanyNanoId)) ||
      startsWith(
        pathname,
        TeamPath.companyPerformers(companyViewCompanyNanoId)
      ) ||
      startsWith(pathname, TeamPath.companyUsers(companyViewCompanyNanoId)) ||
      startsWith(
        pathname,
        TeamPath.companyItemTypes(companyViewCompanyNanoId)
      ) ||
      startsWith(
        pathname,
        TeamPath.companyIncomingRevenueShare(companyViewCompanyNanoId)
      ) ||
      startsWith(
        pathname,
        TeamPath.companyOutgoingRevenueShare(companyViewCompanyNanoId)
      ))
  ) {
    return PrimaryNavTabsEnum.COMPANY_VIEW_DASHBOARD;
  }

  if (
    companyViewCompanyNanoId &&
    startsWith(pathname, TeamPath.companyPayments(companyViewCompanyNanoId))
  ) {
    return PrimaryNavTabsEnum.COMPANY_VIEW_PAYMENTS;
  }

  if (
    companyViewCompanyNanoId &&
    (startsWith(pathname, TeamPath.companyProjects(companyViewCompanyNanoId)) ||
      startsWith(pathname, TeamPath.companyTasks(companyViewCompanyNanoId)) ||
      startsWith(pathname, TeamPath.companyMessages(companyViewCompanyNanoId)))
  ) {
    return PrimaryNavTabsEnum.COMPANY_VIEW_WORKSPACE;
  }

  if (
    companyViewCompanyNanoId &&
    startsWith(pathname, TeamPath.companyLibrary(companyViewCompanyNanoId))
  ) {
    return PrimaryNavTabsEnum.COMPANY_VIEW_LIBRARY;
  }

  if (companyViewCompanyNanoId) {
    return null;
  }

  // Base View Tabs

  if (startsWith(pathname, TeamPath.currentCompanyLibrary())) {
    return PrimaryNavTabsEnum.COMPANY_LIBRARY;
  }

  if (startsWith(pathname, TeamPath.currentCompany())) {
    return PrimaryNavTabsEnum.DASHBOARD;
  }

  // if () {
  //   return PrimaryNavTabsEnum.NOTIFICATIONS;
  // }

  if (
    startsWith(pathname, TaskPath.compactIndex()) ||
    startsWith(pathname, ProjectPath.compactIndex())
  ) {
    return PrimaryNavTabsEnum.COMPACT_WORKSPACE;
  }

  if (
    startsWith(pathname, MessagePath.index()) ||
    startsWith(pathname, ProjectPath.index()) ||
    startsWith(pathname, TaskPath.index())
  ) {
    return PrimaryNavTabsEnum.TASKS_AND_PROJECTS;
  }

  if (
    startsWith(pathname, ProductPath.index()) ||
    startsWith(pathname, LifestylePath.index()) ||
    startsWith(pathname, MaterialPath.index())
  ) {
    return PrimaryNavTabsEnum.STOCK;
  }

  if (startsWith(pathname, StockPath.show())) {
    return PrimaryNavTabsEnum.NEW_STOCK;
  }

  if (startsWith(pathname, UserPath.index())) {
    return PrimaryNavTabsEnum.USERS;
  }

  if (
    startsWith(pathname, BillPath.index()) ||
    startsWith(pathname, RolePath.index()) ||
    startsWith(pathname, PermissionPath.index()) ||
    startsWith(pathname, EmailTemplatePath.index()) ||
    startsWith(pathname, SectionPath.index()) ||
    startsWith(pathname, CategoryPath.index()) ||
    startsWith(pathname, SentMessagePath.index()) ||
    startsWith(pathname, ReportPath.index()) ||
    startsWith(pathname, PagePath.index()) ||
    startsWith(pathname, ItemTypePath.default()) ||
    startsWith(pathname, ItemTypePath.index()) ||
    startsWith(pathname, ItemCategoryPath.index()) ||
    startsWith(pathname, AvBillingInfoPath.index()) ||
    startsWith(pathname, InvoicePath.depositInvoices()) ||
    startsWith(pathname, DefaultSmartContractRulePath.smartContractFees()) ||
    startsWith(pathname, JournalRecordPath.accountingTransactions()) ||
    startsWith(pathname, InvoicePath.index()) ||
    startsWith(pathname, JournalRecordImportsPath.transactionImports()) ||
    startsWith(pathname, InvoiceProcessingJobsPath.index()) ||
    startsWith(
      pathname,
      JournalRecordImportRowsPath.journalRecordImportRows()
    ) ||
    startsWith(pathname, ReportSectionPath.index()) ||
    startsWith(pathname, ChatGptPromptPath.index())
  ) {
    return PrimaryNavTabsEnum.ADMIN;
  }

  if (startsWith(pathname, PaymentPath.index())) {
    return PrimaryNavTabsEnum.PAYMENTS;
  }

  if (
    startsWith(pathname, UserPath.clients()) ||
    startsWith(pathname, TeamPath.companies()) ||
    startsWith(pathname, UserPath.workers()) ||
    startsWith(pathname, TeamPath.performers())
  ) {
    return PrimaryNavTabsEnum.PEOPLE;
  }

  if (startsWith(pathname, SupersetReportPath.index())) {
    return PrimaryNavTabsEnum.REPORTS;
  }

  return null;
}

export default getCurrentPathTab;
