import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface OperationsKeyType {
  activityDate: LocalesKey;
  addRecord: LocalesKey;
  addRecordAndBlock: LocalesKey;
  record: LocalesKey;
  recordAbout: LocalesKey;
  recordAdded: LocalesKey;
  records: LocalesKey;
  recordUpdated: LocalesKey;
  status: LocalesKey;
  recorded: LocalesKey;
  recordedDate: LocalesKey;
  dueDate: LocalesKey;
  completed: LocalesKey;
  completedDate: LocalesKey;
  createCompanyRecord: LocalesKey;
  description: LocalesKey;
  lastRecord: LocalesKey;
  leaveOfAbsence: LocalesKey;
  subject: LocalesKey;
  statuses: {
    planned: LocalesKey;
    completed: LocalesKey;
    not_started: LocalesKey;
    in_progress: LocalesKey;
    canceled: LocalesKey;
    waiting_for_input: LocalesKey;
    deferred: LocalesKey;
  };
  tat: LocalesKey;
  types: {
    call: LocalesKey;
    event: LocalesKey;
    task: LocalesKey;
  };
  typeOfActivity: LocalesKey;
  messages: {
    addConfirm: {
      areYouSureYouWantToAdd: LocalesKey;
      record: LocalesKey;
    };
  };
  noTat: LocalesKey;
  notified: LocalesKey;
  updateRecord: LocalesKey;
}

export const operationsKeys = keyPathMirror<OperationsKeyType, string>(
  {
    activityDate: null,
    addRecord: null,
    addRecordAndBlock: null,
    record: null,
    recordAbout: null,
    recordAdded: null,
    records: null,
    recordUpdated: null,
    status: null,
    recorded: null,
    recordedDate: null,
    dueDate: null,
    completed: null,
    completedDate: null,
    createCompanyRecord: null,
    description: null,
    lastRecord: null,
    leaveOfAbsence: null,
    subject: null,
    statuses: {
      planned: null,
      completed: null,
      not_started: null,
      in_progress: null,
      canceled: null,
      waiting_for_input: null,
      deferred: null
    },
    tat: null,
    types: {
      call: null,
      event: null,
      task: null
    },
    typeOfActivity: null,
    messages: {
      addConfirm: {
        areYouSureYouWantToAdd: null,
        record: null
      }
    },
    noTat: null,
    notified: null,
    updateRecord: null
  },
  'models.operations.'
);
