import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface ProductsKeyType {
  threeDStock: LocalesKey;
  aiTagsUpdate: LocalesKey;
  attachSelected: LocalesKey;
  clearProductCache: LocalesKey;
  clearProductsCache: LocalesKey;
  created: LocalesKey;
  clearAllCustomTags: LocalesKey;
  customTags: LocalesKey;
  companyProduct: LocalesKey;
  download: LocalesKey;
  downloadModel: LocalesKey;
  my: LocalesKey;
  plural: LocalesKey;
  singular: LocalesKey;
  search: LocalesKey;
  searchCustomTags: LocalesKey;
  searchProductsByNameOrSku: LocalesKey;
  addProduct: LocalesKey;
  attach: LocalesKey;
  attachProduct: LocalesKey;
  all: LocalesKey;
  associatedAssets: LocalesKey;
  deleteProduct: LocalesKey;
  deleteWarning: LocalesKey;
  category: LocalesKey;
  editProduct: LocalesKey;
  noPlural: LocalesKey;
  name: LocalesKey;
  selected: LocalesKey;
  similar: LocalesKey;
  viewAll: LocalesKey;
  categories: {
    architecturalPart: LocalesKey;
    equipmentAndDevices: LocalesKey;
    exterior: LocalesKey;
    homeDecor: LocalesKey;
    homeTextiles: LocalesKey;
    kitchen: LocalesKey;
    plants: LocalesKey;
  };
  oneOrMoreSkuWasNotFoundInProductLibrary: LocalesKey;
}

export const productsKeys = keyPathMirror<ProductsKeyType, string>(
  {
    threeDStock: null,
    aiTagsUpdate: null,
    attachSelected: null,
    clearProductCache: null,
    clearProductsCache: null,
    created: null,
    clearAllCustomTags: null,
    customTags: null,
    companyProduct: null,
    download: null,
    downloadModel: null,
    my: null,
    plural: null,
    singular: null,
    search: null,
    searchCustomTags: null,
    searchProductsByNameOrSku: null,
    addProduct: null,
    attach: null,
    attachProduct: null,
    all: null,
    associatedAssets: null,
    deleteProduct: null,
    deleteWarning: null,
    category: null,
    editProduct: null,
    noPlural: null,
    name: null,
    selected: null,
    similar: null,
    viewAll: null,
    categories: {
      architecturalPart: null,
      equipmentAndDevices: null,
      exterior: null,
      homeDecor: null,
      homeTextiles: null,
      kitchen: null,
      plants: null
    },
    oneOrMoreSkuWasNotFoundInProductLibrary: null
  },
  'models.products.'
);
