export default {
  plural: 'Files',
  addFile: 'Add file',
  filesAdded: 'Files Added',
  filesAttached: 'Files attached',
  failedToUpload: 'Failed to upload file',
  fileUploaded: 'File uploaded',
  fileFromComputer: 'File from computer',
  fileFromProject: 'File from project',
  attach: 'Attach files',
  attachFromProject: 'Attach files from project',
  png: 'PNG',
  jpg: 'JPG',
  tiff: 'TIFF',
  selectFileFormat: 'Select file format',
  selectFiles: 'Select files',
  startFilesUploading: 'Start files uploading',
  viewLdFile: 'View LD file'
};
