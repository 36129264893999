import { AccountTypeNanoID } from '../accountTypes/accountTypesTypes';
import { CompanySmartContractTemplateNanoID } from '../companySmartContractTemplates/companySmartContractTemplatesTypes';
import { InvoiceNanoID } from '../invoices/invoicesTypes';
import { FetchFinJournalRecordsFilters } from '../journalRecords/journalRecordsTypes';
import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { SmartContractAutoCheckInRuleNanoID } from '../smartContractAutoCheckInRules/smartContractAutoCheckInRulesTypes';
import { TaskStatuses } from '../tasks/tasksTypes';
import { UserNanoID } from '../users/usersTypes';
import { TeamNanoID } from './teamsTypes';
import { ProductNanoID } from '../products/productsTypes';
import { LifestyleNanoID } from '../lifestyles/lifestylesTypes';
import { MaterialNanoID } from '../materials/materialsTypes';

const companiesBasePath = '/companies';
const companyBasePathname = `${companiesBasePath}/[slug]`;
const performersBasePath = '/performers';
const currentCompanyBasePath = '/company';
const attachmentsBasePath = '/attachments';

export class TeamPath {
  static companyPathname() {
    return companyBasePathname;
  }

  static companyProfile(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile`;
  }

  static companyPerformers(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/performers`;
  }

  static companyUsers(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/users`;
  }

  static companyItemTypes(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/item-types`;
  }

  static companyPayments(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments`;
  }

  static companyDashboard(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/dashboard`;
  }

  static companyRecords(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/records`;
  }

  static companyReports(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/reports`;
  }

  static companyReportsTasksWithoutSourceFiles(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/reports/tasks-without-source-files`;
  }

  static companySubbooks(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/subbooks`;
  }

  static companyAutoCheckInRules(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/auto-check-in-rules`;
  }

  static companySmartContractTemplates(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/smart-contract-templates`;
  }

  static companyEventDispatcher(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/event-dispatcher`;
  }

  static companyAutoCheckInRuleShares(
    teamNanoId: TeamNanoID,
    templateNanoId: SmartContractAutoCheckInRuleNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/auto-check-in-rules/${templateNanoId}`;
  }

  static companySmartContractTemplateShares(
    teamNanoId: TeamNanoID,
    templateNanoId: CompanySmartContractTemplateNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/smart-contract-templates/${templateNanoId}`;
  }

  static companyAttachments(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}${attachmentsBasePath}`;
  }

  static companyFiles(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}${attachmentsBasePath}/files`;
  }

  static companyImages(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}${attachmentsBasePath}/images`;
  }

  static companyUserProfile(teamNanoId: TeamNanoID, userNanoId: UserNanoID) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}`;
  }

  static companyUserDashboard(teamNanoId: TeamNanoID, userNanoId: UserNanoID) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/dashboard`;
  }

  static companyUserRequirements(
    teamNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/requirements`;
  }

  static companyUserRecords(teamNanoId: TeamNanoID, userNanoId: UserNanoID) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/records`;
  }

  static companyUserAttachments(
    teamNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/attachments`;
  }

  static companyUserImages(teamNanoId: TeamNanoID, userNanoId: UserNanoID) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/attachments/images`;
  }

  static companyUserFiles(teamNanoId: TeamNanoID, userNanoId: UserNanoID) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/attachments/files`;
  }

  static companies() {
    return companiesBasePath;
  }

  static companiesFavorite() {
    return `${companiesBasePath}/filters/favorite`;
  }

  static companiesUnassigned() {
    return `${companiesBasePath}/filters/unassigned`;
  }
  static companiesStatusNew() {
    return `${companiesBasePath}/filters/status/new`;
  }

  static companiesStatusActive() {
    return `${companiesBasePath}/filters/status/active`;
  }

  static companiesStatusPaused() {
    return `${companiesBasePath}/filters/status/paused`;
  }

  static companiesStatusDeclined() {
    return `${companiesBasePath}/filters/status/declined`;
  }

  static companiesStatusRealDecline() {
    return `${companiesBasePath}/filters/status/real-decline`;
  }

  static companiesStatusNoPayments() {
    return `${companiesBasePath}/filters/status/no-payments`;
  }

  static companiesFilters(searchFilterNanoId: SearchFilterNanoID) {
    return `${companiesBasePath}/filters/${searchFilterNanoId}`;
  }

  static companiesMyCompanies() {
    return `${companiesBasePath}/filters/my-companies`;
  }

  static performers() {
    return performersBasePath;
  }

  static performersFavorite() {
    return `${performersBasePath}/filters/favorite`;
  }

  static performersUnassigned() {
    return `${performersBasePath}/filters/unassigned`;
  }

  static performersMyPerformers() {
    return `${performersBasePath}/filters/my-performers`;
  }

  static performersTat() {
    return `${performersBasePath}/view/tat`;
  }

  static performersBlocked() {
    return `${performersBasePath}/filters/blocked`;
  }

  static performersFilters(searchFilterNanoId: SearchFilterNanoID) {
    return `${performersBasePath}/filters/${searchFilterNanoId}`;
  }

  static companyProfileRequirements(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/requirements`;
  }

  static companyProfileStatus(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/status`;
  }

  static companyProfileSettings(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/settings`;
  }

  static companyProfileApi(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/api`;
  }

  static companyProfileMarketingData(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/marketing-data`;
  }

  static companyProfileSmartContractSettings(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/smart-contract-settings`;
  }

  static companyProfilePermissions(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/permissions`;
  }

  static companyProfilePersonalization(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/personalization`;
  }

  static companyProfileDescription(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/description`;
  }

  static companyProfileDocuments(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/documents`;
  }

  static companyProfileAirTable(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/airtable`;
  }

  static companyProfileTerms(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/terms`;
  }

  static companyProfileBillingInfo(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/profile/billing-info`;
  }

  static companyPaymentsBalanceSheet(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/balance-sheet`;
  }

  static companyPaymentsTransactions(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/transactions`;
  }

  static companyPaymentsContractorInvoices(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/performer-invoices`;
  }

  static companyPaymentsContractorInvoicesOpen(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/performer-invoices/filters/open`;
  }

  static companyPaymentsContractorInvoicesSent(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/performer-invoices/filters/sent`;
  }

  static companyPaymentsContractorInvoicesCurrent(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/performer-invoices/filters/current`;
  }

  static companyPaymentsContractorInvoicesOverdue(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/performer-invoices/filters/overdue`;
  }

  static companyPaymentsTransactionsAccountType(
    teamNanoId: TeamNanoID,
    accountTypeNanoId: AccountTypeNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/payments/transactions/filters/account-types/${accountTypeNanoId}`;
  }

  static companyPaymentsTransactionsAccountTypeWithFilters(
    teamNanoId: TeamNanoID,
    accountTypeNanoId: AccountTypeNanoID,
    filters: FetchFinJournalRecordsFilters
  ) {
    return `${companiesBasePath}/${teamNanoId}/payments/transactions/filters/account-types/${accountTypeNanoId}?filters=${JSON.stringify(
      filters
    )}`;
  }

  static companyPaymentsSmartContracts(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/smart-contracts`;
  }

  static companyPaymentsSmartContractsDraftFilter(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/smart-contracts/filters/draft`;
  }

  static companyPaymentsSmartContractsCanceledFilter(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/smart-contracts/filters/canceled`;
  }

  static companyPaymentsInvoices(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/invoices`;
  }

  static companyPaymentsInvoicesInvoice(
    teamNanoId: TeamNanoID,
    invoiceNanoId: InvoiceNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/payments/invoices/${invoiceNanoId}`;
  }

  static companyPaymentsInvoicesFilter(
    teamNanoId: TeamNanoID,
    filterSlug: string
  ) {
    return `${companiesBasePath}/${teamNanoId}/payments/invoices/filters/${filterSlug}`;
  }

  static companyPaymentsInvoicesSentFilter(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/invoices/filters/sent`;
  }

  static companyPaymentsInvoicesDraftFilter(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/invoices/filters/draft`;
  }

  static companyPaymentsInvoicesPaidFilter(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/invoices/filters/paid`;
  }

  static companyPaymentsInvoicesCanceledFilter(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/invoices/filters/canceled`;
  }

  static companyPaymentsInvoicesRefundsFilter(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/invoices/filters/refund`;
  }

  static companyPaymentsProforma(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/proforma`;
  }

  static companyPaymentsProformaV1(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/proforma-v1`;
  }

  static companyPaymentsProformaV2(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/payments/proforma-v2`;
  }

  static companyIncomingRevenueShare(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/incoming-revenue-share`;
  }

  static companyOutgoingRevenueShare(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/outgoing-revenue-share`;
  }

  static currentCompanyIncomingRevenueShare() {
    return `${currentCompanyBasePath}/incoming-revenue-share`;
  }

  static currentCompanyOutgoingRevenueShare() {
    return `${currentCompanyBasePath}/outgoing-revenue-share`;
  }

  static companyProjects(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/projects`;
  }

  static companyProjectsBurning(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/projects/filters/burning`;
  }

  static companyProjectsFavorite(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/projects/filters/favorite`;
  }

  static companyTasks(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/tasks`;
  }

  static companyTasksBurning(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/tasks/filters/burning`;
  }

  static companyTasksFavorite(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/tasks/filters/favorite`;
  }

  static companyTasksInProgress(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/tasks/filters/${TaskStatuses.IN_PROGRESS}`;
  }

  static companyTasksByStatus(teamNanoId: TeamNanoID, status: TaskStatuses) {
    return `${companiesBasePath}/${teamNanoId}/tasks/filters/${status}`;
  }

  static companyTasksResults(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/tasks/results`;
  }

  static companyMessages(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/messages`;
  }

  static companyMessagesNew(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/messages/filters/new`;
  }

  static companyUserTasks(teamNanoId: TeamNanoID, userNanoId: UserNanoID) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/tasks`;
  }

  static companyUserTasksResults(
    teamNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/tasks/results`;
  }

  static companyUserTasksFavorite(
    teamNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/tasks/filters/favorite`;
  }

  static companyUserTasksBurning(
    teamNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/tasks/filters/burning`;
  }

  static companyUserProjects(teamNanoId: TeamNanoID, userNanoId: UserNanoID) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/projects`;
  }

  static companyUserProjectsBurning(
    teamNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/projects/filters/burning`;
  }

  static companyUserProjectsFavorite(
    teamNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/projects/filters/favorite`;
  }

  static companyUserMessages(teamNanoId: TeamNanoID, userNanoId: UserNanoID) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/messages`;
  }

  static companyUserMessagesNew(
    teamNanoId: TeamNanoID,
    userNanoId: UserNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/users/${userNanoId}/messages/filters/new`;
  }

  static companyLibrary(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/library`;
  }

  static companyLibraryProducts(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/library/products`;
  }

  static companyLibraryMaterial(
    teamNanoId: TeamNanoID,
    materialNanoId: MaterialNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/library/materials/${materialNanoId}`;
  }

  static companyLibraryLifestyle(
    teamNanoId: TeamNanoID,
    lifestyleNanoId: LifestyleNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/library/lifestyles/${lifestyleNanoId}`;
  }

  static currentCompanyLibraryLifestyle(lifestyleNanoId: LifestyleNanoID) {
    return `${currentCompanyBasePath}/library/lifestyles/${lifestyleNanoId}`;
  }

  static currentCompanyLibraryMaterial(materialNanoId: MaterialNanoID) {
    return `${currentCompanyBasePath}/library/materials/${materialNanoId}`;
  }

  static companyLibraryProduct(
    teamNanoId: TeamNanoID,
    productNanoId: ProductNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/library/products/${productNanoId}`;
  }

  static editCompanyLibraryProduct(
    teamNanoId: TeamNanoID,
    productNanoId: ProductNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/library/products/${productNanoId}/edit`;
  }

  static editCompanyLibraryLifestyle(
    teamNanoId: TeamNanoID,
    lifestyleNanoId: LifestyleNanoID
  ) {
    return `${companiesBasePath}/${teamNanoId}/library/lifestyles/${lifestyleNanoId}/edit`;
  }

  static companyLibraryLifestyles(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/library/lifestyles`;
  }

  static companyLibraryMaterials(teamNanoId: TeamNanoID) {
    return `${companiesBasePath}/${teamNanoId}/library/materials`;
  }

  // Current company

  static currentCompanyProfile() {
    return `${currentCompanyBasePath}/profile`;
  }

  static currentCompanyDashboard() {
    return `${currentCompanyBasePath}/dashboard`;
  }

  static currentCompanyRecords() {
    return `${currentCompanyBasePath}/records`;
  }

  static currentCompanyReports() {
    return `${currentCompanyBasePath}/reports`;
  }

  static currentCompanyReportsTasksWithoutSourceFiles() {
    return `${currentCompanyBasePath}/reports/tasks-without-source-files`;
  }

  static currentCompanyPerformers() {
    return `${currentCompanyBasePath}/performers`;
  }

  static currentCompanyRevenueShare() {
    return `${currentCompanyBasePath}/revenue-share`;
  }

  static currentCompanyUsers() {
    return `${currentCompanyBasePath}/users`;
  }

  static currentCompanyItemTypes() {
    return `${currentCompanyBasePath}/item-types`;
  }

  static currentCompanyPayments() {
    return `${currentCompanyBasePath}/payments`;
  }

  static currentCompanySubbooks() {
    return `${currentCompanyBasePath}/subbooks`;
  }

  static currentCompanyAutoCheckInRules() {
    return `${currentCompanyBasePath}/auto-check-in-rules`;
  }

  static currentCompanySmartContractTemplates() {
    return `${currentCompanyBasePath}/smart-contract-templates`;
  }

  static currentCompanyEventDispatcher() {
    return `${currentCompanyBasePath}/event-dispatcher`;
  }

  static currentCompanyAutoCheckInRuleShares(
    templateNanoId: SmartContractAutoCheckInRuleNanoID
  ) {
    return `${currentCompanyBasePath}/auto-check-in-rules/${templateNanoId}`;
  }

  static currentCompanySmartContractTemplateShares(
    templateNanoId: CompanySmartContractTemplateNanoID
  ) {
    return `${currentCompanyBasePath}/smart-contract-templates/${templateNanoId}`;
  }

  static currentCompanyAttachments() {
    return `${currentCompanyBasePath}${attachmentsBasePath}`;
  }

  static currentCompanyFiles() {
    return `${currentCompanyBasePath}${attachmentsBasePath}/files`;
  }

  static currentCompanyImages() {
    return `${currentCompanyBasePath}${attachmentsBasePath}/images`;
  }

  static currentCompany() {
    return currentCompanyBasePath;
  }

  static currentCompanyProfileRequirements() {
    return `${currentCompanyBasePath}/profile/requirements`;
  }

  static currentCompanyProfileStatus() {
    return `${currentCompanyBasePath}/profile/status`;
  }

  static currentCompanyProfileSettings() {
    return `${currentCompanyBasePath}/profile/settings`;
  }

  static currentCompanyProfileApi() {
    return `${currentCompanyBasePath}/profile/api`;
  }

  static currentCompanyProfileMarketingData() {
    return `${currentCompanyBasePath}/profile/marketing-data`;
  }

  static currentCompanyProfileSmartContractSettings() {
    return `${currentCompanyBasePath}/profile/smart-contract-settings`;
  }

  static currentCompanyProfilePermissions() {
    return `${currentCompanyBasePath}/profile/permissions`;
  }

  static currentCompanyProfilePersonalization() {
    return `${currentCompanyBasePath}/profile/personalization`;
  }

  static currentCompanyProfileTerms() {
    return `${currentCompanyBasePath}/profile/terms`;
  }

  static currentCompanyProfileDescription() {
    return `${currentCompanyBasePath}/profile/description`;
  }

  static currentCompanyProfileDocuments() {
    return `${currentCompanyBasePath}/profile/documents`;
  }

  static currentCompanyProfileAirTable() {
    return `${currentCompanyBasePath}/profile/airtable`;
  }

  static currentCompanyProfileBillingInfo() {
    return `${currentCompanyBasePath}/profile/billing-info`;
  }

  static currentCompanyLibrary() {
    return `${currentCompanyBasePath}/library`;
  }

  static currentCompanyLibraryProduct(productNanoId: ProductNanoID) {
    return `${currentCompanyBasePath}/library/products/${productNanoId}`;
  }

  static currentCompanyLibraryProducts() {
    return `${currentCompanyBasePath}/library/products`;
  }

  static currentCompanyLibraryLifestyles() {
    return `${currentCompanyBasePath}/library/lifestyles`;
  }

  static currentCompanyLibraryMaterials() {
    return `${currentCompanyBasePath}/library/materials`;
  }
}
