import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface UEStreamingsKeyType {
  openCameraMenu: LocalesKey;
  closeCameraMenu: LocalesKey;
  cameraSettings: LocalesKey;
  enterFullscreen: LocalesKey;
  exitFullscreen: LocalesKey;
  exitStudio: LocalesKey;
}

export const ueStreamingsKeys = keyPathMirror<UEStreamingsKeyType, string>(
  {
    openCameraMenu: null,
    closeCameraMenu: null,
    cameraSettings: null,
    enterFullscreen: null,
    exitFullscreen: null,
    exitStudio: null
  },
  'models.ueStreamings.'
);
