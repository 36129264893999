import { FeedBackFormData } from '../../FeedBackForm.types';
import { FeedBackFields } from '../../../../feedBacksTypes';

import {
  CREATE_FEEDBACK_QUERY,
  FeedBackQueryResponse
} from '../../../../queries/createFeedBack.query';

import { useCreateFeedBack } from '../../../../hooks/useCreateFeedBack';
import { useFeedBackValidationRules } from '../useFeedBackValidationRules';
import { useReactHookForm } from '../../../../../common/hooks/base/useReactHookForm';
import { useToastNotification } from '../../../../../../common/hooks/useToastNotification';

import { messagesKeys } from '../../../../../../locales/keys';

interface FeedBackFormOptions {
  isAuthenticated: boolean;
}

function useFeedBackForm({ isAuthenticated }: FeedBackFormOptions) {
  const defaultFeedBackFormData: FeedBackFormData = {
    name: undefined,
    email: undefined,
    subject: undefined,
    body: undefined
  };

  const { control, errors, handleSubmitReactHookForm, register, resetForm } =
    useReactHookForm<FeedBackFormData>({
      defaultValues: defaultFeedBackFormData,
      isModalForm: true
    });

  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: messagesKeys.yourRequestHasBeenSuccessfullySubmitted
  });

  const {
    createFeedBackLoading,
    createFeedBackErrorMessage,
    createFeedBack,
    createFeedBackReset
  } = useCreateFeedBack<FeedBackQueryResponse>({
    query: CREATE_FEEDBACK_QUERY
  });

  const { nameRules, emailRules, subjectRules, bodyRules } =
    useFeedBackValidationRules();

  return {
    control,
    resetCreateFeedBackForm: resetForm,
    registerFields: {
      registerName: register(
        FeedBackFields.NAME,
        isAuthenticated ? undefined : nameRules
      ),
      registerEmail: register(
        FeedBackFields.EMAIL,
        isAuthenticated ? undefined : emailRules
      ),
      registerSubject: register(FeedBackFields.SUBJECT, subjectRules),
      registerBody: register(FeedBackFields.BODY, bodyRules)
    },
    createFeedBackLoading,
    createFeedBackErrorMessage,
    createFeedBackReset,
    validationErrors: {
      feedBackNameValidationError: errors?.name?.['message'],
      feedBackEmailValidationError: errors?.email?.['message'],
      feedBackSubjectValidationError: errors?.subject?.['message'],
      feedBackBodyValidationError: errors?.body?.['message']
    },

    handleSubmitCreateFeedBack: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data: FeedBackFormData) => {
        createFeedBack(data);
        showToastI18nNotification();
      }
    })
  };
}

export default useFeedBackForm;
