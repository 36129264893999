const basePath = '/3d-stock';

export class StockPath {
  static index() {
    return basePath;
  }

  static show() {
    return basePath;
  }

  static products() {
    return `${basePath}/products`;
  }

  static lifestyles() {
    return `${basePath}/lifestyles`;
  }

  static materials() {
    return `${basePath}/materials`;
  }

  static newProducts() {
    return `${basePath}/new-products`;
  }

  static newLifestyles() {
    return `${basePath}/new-lifestyles`;
  }

  static newMaterials() {
    return `${basePath}/new-materials`;
  }
}
