import {
  CreatedAt,
  DateType,
  DeviceNanoId,
  ID,
  ModelBrand,
  MoneyType,
  NanoID,
  UpdatedAt,
  UUID
} from '../../../types';

import {
  ProjectID,
  ProjectName,
  ProjectNanoID,
  ProjectUserFullName,
  ProjectUserImageFile,
  ProjectUserImageUUID,
  ProjectUserNanoID,
  ProjectUserRoleName,
  ProjectUserUUID,
  ProjectUserID,
  ProjectUUID,
  ProjectUserFinanceRoleName,
  ProjectUserClient,
  ProjectUserNDA,
  ProjectUserBlocked,
  ProjectTeamNanoID,
  ProjectTeamName,
  ProjectTeamImageFile,
  ProjectTeam,
  ProjectTeamID,
  ProjectTeamNda,
  ProjectOwnerID,
  ProjectOwnerClient,
  ProjectTeamCreatedAt
} from '../../projects/projectsTypes';

import {
  UserBlocked,
  UserFinanceRoleName,
  UserFullName,
  UserID,
  UserImageFile,
  UserImageUUID,
  UserNanoID,
  UserRoleName,
  UserUUID,
  UserClient,
  UserCurrentTeamNanoID
} from '../../users/usersTypes';

import {
  FileAttachmentCreatedAt,
  FileAttachmentFile,
  FileAttachmentID,
  FileAttachmentName,
  FileAttachmentSize,
  FileAttachmentUUID,
  FileAttachmentWidth,
  FileAttachmentHeight,
  FileAttachmentDPI,
  FileAttachmentWhiteboardKeyID,
  FileAttachmentOriginalFileName
} from '../../fileAttachments/fileAttachmentsTypes';

import {
  MessageBillAmount,
  MessageBillBody,
  MessageBillPayable,
  MessageBody,
  MessageCreatedAt,
  MessageFavorite,
  MessageFileAttachmentIDs,
  MessageFileAttachmentsFile,
  MessageFileAttachmentsName,
  MessageFileAttachmentsReference,
  MessageFileAttachmentsSize,
  MessageFileAttachmentsType,
  MessageFlagged,
  MessageForwardedMessageTaskName,
  MessageHours,
  MessageID,
  MessageIsResult,
  MessageMessageType,
  MessageMinutes,
  MessagePaidBillCurrency,
  MessagePaidBillID,
  MessagePinnedAt,
  MessageSelectedLifestylesCreatedAt,
  MessageSelectedLifestylesLifestyleUUID,
  MessageSelectedMaterialsMaterialImageUUID,
  MessageSelectedMaterialsMaterialUUID,
  MessageSelectedMaterialsType,
  MessageSelectedMaterialsMaterialBrand,
  MessageSelectedMaterialsMaterialImageFile,
  MessageSelectedMaterialsMaterialImageName,
  MessageSelectedProductsProductBrand,
  MessageSelectedProductsProductImageFile,
  MessageSelectedProductsProductImageName,
  MessageSelectedProductsType,
  MessageUpdatedAt,
  MessageUserClient,
  MessageUserFullName,
  MessageUserID,
  MessageUserImageFile,
  MessageUserNanoID,
  MessageUUID,
  MessageVisibleForClient
} from '../../messages/messagesTypes';

import {
  TagID,
  TagLocalizedName,
  TagName,
  TagUUID
} from '../../tags/tagsTypes';

import { SelectedProductID } from '../../selectedProducts/selectedProductsTypes';

import { SelectedMaterialID } from '../../selectedMaterials/selectedMaterialsTypes';

import { SelectedLifestyleID } from '../../selectedLifestyles/selectedLifestylesTypes';
import { TeamUseLdApiAt } from '../../teams/teamsTypes';

export type TaskID = ID;
export type TaskUUID = UUID;
export type TaskNanoID = ModelBrand<NanoID, 'TaskNanoID'>;
export type TaskLdSku = string;
export type TaskLdCustomerID = string;
export type TaskLdProductID = string;

export type TaskNotificationsEnabled = boolean;
export type TaskFavorite = boolean;
export type TaskName = string;
export type TaskDescription = string;
export type TaskWidth = number;
export type TaskHeight = number;
export type TaskDpi = number;
export type TaskStatus = TaskStatusOptions;
export type TaskSetDate = DateType;
export type TaskImplementationDate = DateType;
export type TaskImplementedAt = DateType;
export type TaskLastActivity = DateType;
export type TaskStartDate = DateType;
export type TaskExpectedTime = number;
export type TaskBurning = boolean;
export type TaskRequirements = string[];
export type TaskDebt = MoneyType;
export type TaskRoleDebt = MoneyType;
export type TaskAmount = MoneyType;
export type TaskPrepayment = MoneyType;
export type TaskClientPrepayment = ModelBrand<
  MoneyType,
  'TaskClientPrepayment'
>;
export type TaskTaskAccount = MoneyType;
export type TaskCreatedAt = CreatedAt;
export type TaskStatusUpdatedAt = UpdatedAt;
export type TaskUpdatedAt = UpdatedAt;
export type TaskDeviceNanoId = DeviceNanoId;
export type TaskVisibleForClient = boolean;
export type TaskStudioEmbedID = string;
export type TaskLdAuthToken = string;
export type TaskRed = boolean;
export type TaskPausedAt = DateType | null;
export type TaskRevisionedAt = DateType | null;
export type TaskSupportMark = number;
export type TaskMark = number;
export type TaskSourceFilesCount = number;

export type TaskVersionNumber = number;
export type TaskVersionMessageID = MessageID;
export type TaskVersionMessageUUID = MessageUUID;
export type TaskVersionMessageBody = MessageBody;

export type TaskCoefficient = number;

export type TaskCreateTasksBillsAccount = string;
export type TaskCreateTasksBillsAmount = number;
export type TaskCreateTasksBillsBody = string;
export type TaskCreateTasksBillsAllocableUser = string;
export type TaskCreateTasksBillsButtons = string;
export type TaskCreateTasksBillsCurrency = string;
export type TaskCreateTasksBillsTransactionTagId = ID;

export type TaskPrice = number;
export type TaskPaidRegular = number;
export type TaskOpenRegular = number;
export type TaskSentRegular = number;
export type TaskSmartContractLastStatusChangeAt = UpdatedAt;

export type TaskBatchDiscussionsImportId = ID;

export type TasksMetaDebt = MoneyType;

export type TaskState = TaskStates;

export type TaskShowAbortButton = boolean;
export type TaskShowCancelButton = boolean;
export type TaskShowCloseButton = boolean;
export type TaskShowStartButton = boolean;
export type TaskShowPauseButton = boolean;
export type TaskShowPendingMoreInfoButton = boolean;

export type TaskStatusOptions =
  | 'acceptance'
  | 'canceled'
  | 'definition'
  | 'implementation'
  | 'incomplete_specification'
  | 'paused'
  | 'performed'
  | 'qa';

export enum BitTaskStatuses {
  NONE = 'none',
  SETTING = 'setting',
  PROGRESS = 'progress',
  PENDING_MORE_INFO = 'pending_more_info',
  PAUSED = 'paused',
  PENDING_PAYMENTS = 'pending_payments',
  QA = 'qa',
  WAITING_FOR_REVIEW = 'waiting_for_review',
  READY_FOR_ACCEPTANCE = 'ready_for_acceptance',
  DONE = 'done',
  CANCELED = 'canceled'
}

export enum TaskStates {
  DRAFT = 'draft',
  FINAL = 'final'
}

export enum TaskStatuses {
  SETTING_TASK = 'definition',
  IN_PROGRESS = 'implementation',
  QUALITY_CONTROL = 'qa',
  READY_FOR_ACCEPTANCE = 'acceptance',
  DONE = 'performed',
  PENDING_MORE_INFO = 'incomplete_specification',
  CANCELED = 'canceled',
  PAUSED = 'paused'
}

export type TaskFileExtension = 'jpg' | 'png' | 'tiff';

export type TaskProjectID = ProjectID;
export type TaskProjectUUID = ProjectUUID;
export type TaskProjectNanoID = ProjectNanoID;
export type TaskProjectName = ProjectName;
export type TaskProjectOwnerID = ProjectOwnerID;
export type TaskProjectOwnerClient = ProjectOwnerClient;
export type TaskProjectUserUUID = ProjectUserUUID;
export type TaskProjectUserID = ProjectUserID;
export type TaskProjectUserNanoID = ProjectUserNanoID;
export type TaskProjectUserFullName = ProjectUserFullName;
export type TaskProjectUserRoleName = ProjectUserRoleName;
export type TaskProjectUserClient = ProjectUserClient;
export type TaskProjectUserNDA = ProjectUserNDA;
export type TaskProjectUserBlocked = ProjectUserBlocked;
export type TaskProjectUserFinanceRoleName = ProjectUserFinanceRoleName;
export type TaskProjectUserImageUUID = ProjectUserImageUUID;
export type TaskProjectUserImageFile = ProjectUserImageFile;
export type TaskProjectUserCurrentTeamNanoID = UserCurrentTeamNanoID;
export type TaskProjectTeamID = ProjectTeamID;
export type TaskProjectTeamNanoID = ProjectTeamNanoID;
export type TaskProjectTeamUseLdApiAt = TeamUseLdApiAt;
export type TaskProjectTeamName = ProjectTeamName;
export type TaskProjectTeamCreatedAt = ProjectTeamCreatedAt;
export type TaskProjectTeamNda = ProjectTeamNda;
export type TaskProjectTeamImageFile = ProjectTeamImageFile;
export type TaskProjectTeam = ProjectTeam;
export type TaskNotes = string;

export type TaskUserID = UserID;
export type TaskUserUUID = UserUUID;
export type TaskUserNanoID = UserNanoID;
export type TaskUserFullName = UserFullName;
export type TaskUserImageUUID = UserImageUUID;
export type TaskUserImageFile = UserImageFile;
export type TaskUserRoleName = UserRoleName;
export type TaskUserFinanceRoleName = UserFinanceRoleName;
export type TaskUserBlocked = UserBlocked;
export type TaskUserClient = UserClient;
export type TaskUserCurrentTeamNanoID = UserCurrentTeamNanoID;

export type TaskOwnerID = UserID;
export type TaskOwnerUUID = UserUUID;
export type TaskOwnerNanoID = UserNanoID;
export type TaskOwnerFullName = UserFullName;
export type TaskOwnerImageUUID = UserImageUUID;
export type TaskOwnerImageFile = UserImageFile;
export type TaskOwnerRoleName = UserRoleName;
export type TaskOwnerFinanceRoleName = UserFinanceRoleName;
export type TaskOwnerBlocked = UserBlocked;
export type TaskOwnerClient = UserClient;
export type TaskOwnerCurrentTeamNanoID = UserCurrentTeamNanoID;

export type TaskMembersID = UserID;
export type TaskMembersUUID = UserUUID;
export type TaskMembersNanoID = UserNanoID;
export type TaskMembersFullName = UserFullName;
export type TaskMembersImageUUID = UserImageUUID;
export type TaskMembersImageFile = UserImageFile;
export type TaskMembersRoleName = UserRoleName;
export type TaskMembersFinanceRoleName = UserFinanceRoleName;
export type TaskMembersBlocked = UserBlocked;
export type TaskMembersClient = UserClient;
export type TaskMembersCurrentTeamNanoID = UserCurrentTeamNanoID;
export type TaskLastMessageBody = MessageBody;
export type TaskLastMessageCreatedAt = MessageCreatedAt;

export type TaskLastMessageUserID = UserID;
export type TaskLastMessageUserNanoID = UserNanoID;
export type TaskLastMessageUserFullName = UserFullName;
export type TaskLastMessageUserImageUUID = UserImageUUID;
export type TaskLastMessageUserImageFile = UserImageFile;

export type TaskFileAttachmentIds = FileAttachmentID[];
export type TaskFileAttachmentsUUID = FileAttachmentUUID;
export type TaskFileAttachmentsFile = FileAttachmentFile;
export type TaskFileAttachmentMuted = boolean;

export type TaskMessageMessageType = MessageMessageType;
export type TaskMessageFavorite = MessageFavorite;
export type TaskMessageFlagged = MessageFlagged;
export type TaskMessagePinnedAt = MessagePinnedAt;
export type TaskMessageVisibleForClient = MessageVisibleForClient;
export type TaskMessageHours = MessageHours;
export type TaskMessageMinutes = MessageMinutes;
export type TaskMessageCreatedAt = MessageCreatedAt;
export type TaskMessageUpdatedAt = MessageUpdatedAt;
export type TaskMessageTaskID = TaskID;
export type TaskMessageTaskUUID = TaskUUID;
export type TaskMessageTaskNanoID = TaskNanoID;
export type TaskMessageTaskMark = TaskMark;
export type TaskMessageTaskSupportMark = TaskSupportMark;

export type TaskMessageUserID = MessageUserID;
export type TaskMessageUserNanoID = MessageUserNanoID;
export type TaskMessageUserFullName = MessageUserFullName;
export type TaskMessageUserClient = MessageUserClient;
export type TaskMessageUserImageFile = MessageUserImageFile;
export type TaskMessageIsResult = MessageIsResult;
export type TaskMessageBody = MessageBody;

export type TaskMessageBillAmount = MessageBillAmount;
export type TaskMessageBillBody = MessageBillBody;
export type TaskMessageBillPayable = MessageBillPayable;

export type TaskMessagePaidBillID = MessagePaidBillID;
export type TaskMessagePaidBillCurrency = MessagePaidBillCurrency;

export type TaskMessageFileAttachmentIDs = MessageFileAttachmentIDs;
export type TaskMessageFileAttachmentsType = MessageFileAttachmentsType;
export type TaskMessageFileAttachmentsName = MessageFileAttachmentsName;
export type TaskMessageFileAttachmentsFile = MessageFileAttachmentsFile;
export type TaskMessageFileAttachmentsSize = MessageFileAttachmentsSize;
export type TaskMessageFileAttachmentsReference =
  MessageFileAttachmentsReference;
export type TaskMessageSelectedProductsType = MessageSelectedProductsType;
export type TaskMessageSelectedProductsProductBrand =
  MessageSelectedProductsProductBrand;
export type TaskMessageSelectedProductsProductImageName =
  MessageSelectedProductsProductImageName;
export type TaskMessageSelectedProductsProductImageFile =
  MessageSelectedProductsProductImageFile;
export type TaskMessageSelectedLifestylesCreatedAt =
  MessageSelectedLifestylesCreatedAt;
export type TaskMessageSelectedLifestylesLifestyleUUID =
  MessageSelectedLifestylesLifestyleUUID;
export type TaskMessageSelectedMaterialsType = MessageSelectedMaterialsType;
export type TaskMessageSelectedMaterialsMaterialUUID =
  MessageSelectedMaterialsMaterialUUID;
export type TaskMessageSelectedMaterialsMaterialImageUUID =
  MessageSelectedMaterialsMaterialImageUUID;
export type TaskMessageSelectedMaterialsMaterialBrand =
  MessageSelectedMaterialsMaterialBrand;
export type TaskMessageSelectedMaterialsMaterialImageName =
  MessageSelectedMaterialsMaterialImageName;
export type TaskMessageSelectedMaterialsMaterialImageFile =
  MessageSelectedMaterialsMaterialImageFile;

export type TaskMessageForwardedMessageTaskName =
  MessageForwardedMessageTaskName;

export type TaskResultsUploadedAt = DateType;

export type TaskResultFileAttachmentsSize = FileAttachmentSize;

export type TaskVisibleResultFileAttachmentsID = FileAttachmentID;
export type TaskVisibleResultFileAttachmentsUUID = FileAttachmentUUID;
export type TaskVisibleResultFileAttachmentsName = FileAttachmentName;
export type TaskVisibleResultFileAttachmentsOriginalFileName =
  FileAttachmentOriginalFileName;
export type TaskVisibleResultFileAttachmentsFile = FileAttachmentFile;
export type TaskVisibleResultFileAttachmentsSize = FileAttachmentSize;
export type TaskVisibleResultFileAttachmentsCreatedAt = FileAttachmentCreatedAt;
export type TaskVisibleResultFileAttachmentsWidth = FileAttachmentWidth;
export type TaskVisibleResultFileAttachmentsHeight = FileAttachmentHeight;
export type TaskVisibleResultFileAttachmentsDPI = FileAttachmentDPI;
export type TaskVisibleResultFileAttachmentsWhiteboardKeyID =
  FileAttachmentWhiteboardKeyID;

export type TaskTagsID = TagID;
export type TaskTagsUUID = TagUUID;
export type TaskTagsName = TagName;
export type TaskTagsLocalizedName = TagLocalizedName;

export type TaskPerformerIdsFilter = TaskMembersID[];
export type TaskMemberIdsFilter = TaskMembersID[];

export type TaskSelectedProductsID = SelectedProductID;

export type TaskSelectedMaterialsID = SelectedMaterialID;

export type TaskSelectedLifestylesID = SelectedLifestyleID;

export const enum TaskFields {
  AMOUNT = 'amount',
  CREATED_AT = 'createdAt',
  DESCRIPTION = 'description',
  DPI = 'dpi',
  FILE_ATTACHMENT_IDS = 'fileAttachmentIds',
  FILE_ATTACHMENTS = 'fileAttachments',
  FILE_EXTENSION = 'fileExtension',
  HEIGHT = 'height',
  ID = 'id',
  IMPLEMENTATION_DATE = 'implementationDate',
  LD_CUSTOMER_ID = 'ldCustomerId',
  LD_PRODUCT_ID = 'ldProductId',
  LD_SKU = 'ldSku',
  NAME = 'name',
  NANO_ID = 'nanoId',
  NOTES = 'notes',
  PREPAYMENT = 'prepayment',
  PROJECT_ID = 'projectId',
  RED = 'red',
  SOURCE_FILE_IDS = 'sourceFileIds',
  STATUS = 'status',
  STUDIO_EMBED_ID = 'studioEmbedId',
  TAG_IDS = 'tagIds',
  UUID = 'uuid',
  VISIBLE_FOR_CLIENT = 'visibleForClient',
  WIDTH = 'width'
}

export const enum TasksBillsFields {
  AMOUNT = 'amount',
  CURRENCY = 'currency',
  BODY = 'body',
  ALLOCABLE_USER = 'allocableUser',
  ACCOUNT = 'account',
  BUTTONS = 'buttons',
  TRANSACTION_TAG_ID = 'transactionTagId',
  CREATED_AT = 'createdAt'
}

export const enum TaskResultsFields {
  FILE_ATTACHMENT_IDS = 'fileAttachmentIds',
  HOURS = 'hours',
  MINUTES = 'minutes',
  LEXICAL = 'lexical'
}

export const enum TaskTrackTimeFields {
  HOURS = 'hours',
  MINUTES = 'minutes',
  BODY = 'body'
}

export const enum TaskAcceptFields {
  START_DATE = 'startDate',
  IMPLEMENTATION_DATE = 'implementationDate',
  HOURS = 'hours',
  MINUTES = 'minutes'
}

export const enum StartTaskFields {
  EXPECTED_TIME = 'expectedTime',
  IMPLEMENTATION_DATE = 'implementationDate'
}

export const enum TaskRevisionFields {
  START_DATE = 'startDate',
  IMPLEMENTATION_DATE = 'implementationDate',
  FEEDBACK = 'feedback'
}

export const enum TaskApproveResultsFields {
  SUPPORT_MARK = 'supportMark',
  FEEDBACK = 'feedback',
  FINAL = 'final'
}

export const enum TaskAcceptResultsFields {
  MARK = 'mark'
}

export const enum TasksTagFields {
  REMOVE_OTHER_TAGS = 'removeOtherTags'
}

export type TaskCompactVisibleFieldOptions =
  | 'favorite'
  | 'company'
  | 'name'
  | 'team'
  | 'price'
  | 'debt'
  | 'dueDate'
  | 'status'
  | 'items'
  | 'lastActivity'
  | 'createdAt'
  | 'tags'
  | 'sourceFiles'
  | 'invited'
  | 'notes'
  | 'statusUpdatedAt'
  | 'accept'
  | 'totalPrice'
  | 'sentRegular'
  | 'openRegular'
  | 'paidRegular'
  | 'estimateCost'
  | 'lastMessage'
  | 'scTat';

export type TaskCompactVisibleFieldsOptions = TaskCompactVisibleFieldOptions[];

export type TasksCompactVisibleFieldsByStatusesType = {
  [key in TaskStatuses | 'workload' | 'all']?: TaskCompactVisibleFieldsOptions;
};

const tasksCompactCommonFields: TaskCompactVisibleFieldsOptions = [
  'favorite',
  'company',
  'name',
  'team',
  'price',
  'debt',
  'lastMessage',
  'items',
  'notes'
];

export const projectTasksCompactFields: TaskCompactVisibleFieldsOptions = [
  'favorite',
  'company',
  'name',
  'team',
  'price',
  'debt',
  'estimateCost',
  'items',
  'notes',
  'dueDate',
  'status',
  'lastActivity',
  'createdAt',
  'tags',
  'sourceFiles'
];

export const tasksCompactVisibleFieldsByStatuses: TasksCompactVisibleFieldsByStatusesType =
  {
    all: [
      ...tasksCompactCommonFields,
      'dueDate',
      'status',
      'lastActivity',
      'createdAt'
    ],
    workload: [
      ...tasksCompactCommonFields,
      'dueDate',
      'status',
      'lastActivity',
      'createdAt'
    ],
    [TaskStatuses.SETTING_TASK]: [
      ...tasksCompactCommonFields,
      'invited',
      'items'
    ],
    [TaskStatuses.IN_PROGRESS]: [
      ...tasksCompactCommonFields,
      'dueDate',
      'lastActivity'
    ],
    [TaskStatuses.QUALITY_CONTROL]: [
      ...tasksCompactCommonFields,
      'dueDate',
      'lastActivity'
    ],
    [TaskStatuses.READY_FOR_ACCEPTANCE]: [
      ...tasksCompactCommonFields,
      'dueDate',
      'lastActivity',
      'createdAt',
      'accept'
    ],
    [TaskStatuses.PENDING_MORE_INFO]: [
      ...tasksCompactCommonFields,
      'dueDate',
      'lastActivity',
      'createdAt'
    ],
    [TaskStatuses.PAUSED]: [
      ...tasksCompactCommonFields,
      'dueDate',
      'lastActivity',
      'createdAt'
    ],
    [TaskStatuses.DONE]: [...tasksCompactCommonFields, 'lastActivity'],
    [TaskStatuses.CANCELED]: [...tasksCompactCommonFields, 'lastActivity']
  };
