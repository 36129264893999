import React, { ReactNode } from 'react';
import { SimpleModalButtonDefaultProps } from './SimpleModalButton.types';

import { ModalOptions, useModal } from '../../modals/hooks/useModal';

import {
  SimpleModal,
  SimpleModalRenderPropArgs,
  SimpleModalRenderButtonsPropArgs
} from '../../../helpers/modals/SimpleModal';
import { PureIconButtonHelper } from '../PureIconButtonHelper';
import { PureTooltipIconButtonHelper } from '../PureTooltipIconButtonHelper';
import { ButtonHelper } from '../ButtonHelper';
import { GADataType } from '../../../types';

interface SimpleModalButtonProps {
  children: ReactNode | ((args: SimpleModalRenderPropArgs) => ReactNode);
  childrenButtons?: ReactNode;
  renderButtons?: (args: SimpleModalRenderButtonsPropArgs) => ReactNode;
}

function SimpleModalButton({
  addDialogClassName,
  addModalClassName,
  afterSubmit,
  allowPropagation,
  buttonChildren,
  buttonsContainerClassName,
  buttonText,
  cancelButtonPosition,
  children,
  childrenButtons,
  childrenClassName,
  className,
  closeOnCancel,
  dataGa,
  disabled,
  i18nCancelText,
  i18nCloseText,
  i18nSubmitText,
  i18nText,
  i18nTextClassName,
  i18nTitle,
  icon,
  iconClassName,
  id,
  isLoading,
  modalIcon,
  modalIconClassName,
  modalSize,
  notHideOnSubmit,
  onCancel,
  onClose,
  onMouseEnter,
  onOpen,
  onSubmit,
  onToggle,
  preventModalClose,
  renderButtons,
  submitButtonColor,
  submitClass,
  submitDisabled,
  submitIcon,
  submitIconClassName,
  title,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton,
  withCloseButton,
  withoutButtons,
  withoutCancelButton,
  withoutInitialFocus,
  withoutSubmitButton
}: SimpleModalButtonProps &
  SimpleModalButtonDefaultProps &
  ModalOptions &
  GADataType) {
  const { isOpen, showModal, hideModal, handleSubmit, isClosing } = useModal({
    onOpen,
    onClose,
    onToggle,
    onSubmit,
    afterSubmit,
    preventModalClose,
    notHideOnSubmit
  });

  const pureIconI18nTextButton = buttonText ? null : (
    <PureIconButtonHelper
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      icon={icon}
      iconClassName={iconClassName}
      i18nTextClassName={i18nTextClassName}
      i18nText={i18nText}
      onClick={showModal}
      onMouseEnter={onMouseEnter}
    />
  );

  const pureIconTextButton = buttonText ? (
    <PureIconButtonHelper
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      icon={icon}
      iconClassName={iconClassName}
      text={buttonText}
      onClick={showModal}
      onMouseEnter={onMouseEnter}
    />
  ) : null;

  const tooltipI18nTextButton = buttonText ? null : (
    <PureTooltipIconButtonHelper
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      icon={icon}
      iconClassName={iconClassName}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      i18nTextClassName={i18nTextClassName}
      i18nText={i18nText}
      onClick={showModal}
      onMouseEnter={onMouseEnter}
      tooltipSingleton={tooltipSingleton}
    />
  );

  const tooltipTextButton = buttonText ? (
    <PureTooltipIconButtonHelper
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      icon={icon}
      iconClassName={iconClassName}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      text={buttonText}
      onClick={showModal}
      onMouseEnter={onMouseEnter}
      tooltipSingleton={tooltipSingleton}
    />
  ) : null;

  const button = buttonChildren ? (
    <ButtonHelper
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      onClick={showModal}
      onMouseEnter={onMouseEnter}
    >
      {buttonChildren}
    </ButtonHelper>
  ) : null;

  return (
    <>
      {button ||
        tooltipI18nTextButton ||
        tooltipTextButton ||
        pureIconTextButton ||
        pureIconI18nTextButton}

      <SimpleModal
        addDialogClassName={addDialogClassName}
        addModalClassName={addModalClassName}
        allowPropagation={allowPropagation}
        buttonsContainerClassName={buttonsContainerClassName}
        cancelButtonPosition={cancelButtonPosition}
        childrenButtons={childrenButtons}
        childrenClassName={childrenClassName}
        closeOnCancel={closeOnCancel}
        disabled={disabled}
        handleSubmit={handleSubmit}
        hideModal={hideModal}
        i18nCancelText={i18nCancelText}
        i18nCloseText={i18nCloseText}
        i18nSubmitText={i18nSubmitText}
        i18nTitle={i18nTitle}
        id={id}
        isLoading={isLoading}
        isOpen={isOpen}
        modalIcon={modalIcon}
        modalIconClassName={modalIconClassName}
        modalSize={modalSize}
        onCancel={onCancel}
        renderButtons={renderButtons}
        submitButtonColor={submitButtonColor}
        submitClass={submitClass}
        submitDisabled={submitDisabled || isClosing}
        submitIcon={submitIcon}
        submitIconClassName={submitIconClassName}
        title={title}
        withCloseButton={withCloseButton}
        withoutButtons={withoutButtons}
        withoutCancelButton={withoutCancelButton}
        withoutInitialFocus={withoutInitialFocus}
        withoutSubmitButton={withoutSubmitButton}
      >
        {children}
      </SimpleModal>
    </>
  );
}

export default SimpleModalButton;
