import React from 'react';

import { IconProps } from '../types';

function FullscreenEnterIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M4,13H2v5h5v-2H4V13z M2,7h2V4h3V2H2V7z M16,16h-3v2h5v-5h-2V16z M13,2v2h3v3h2V2H13z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FullscreenEnterIcon;
