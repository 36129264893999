const basePath = '/';

const embedBasePath = '/embed';

export class MainPath {
  static index() {
    return basePath;
  }

  static embed() {
    return embedBasePath;
  }
}
