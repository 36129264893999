import { UEStreamingsKeyType } from '../../keys/models/ueStreamings';

const ueStreamingsKeys: UEStreamingsKeyType = {
  openCameraMenu: 'Open camera menu',
  closeCameraMenu: 'Close camera menu',
  cameraSettings: 'Camera settings',
  enterFullscreen: 'Enter fullscreen',
  exitFullscreen: 'Exit fullscreen',
  exitStudio: 'Exit studio'
};

export default ueStreamingsKeys;
