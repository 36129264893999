import React, { ReactNode, useState, useMemo, useCallback } from 'react';
import { usePopper } from 'react-popper';
import { detectOverflow, Options } from '@popperjs/core';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import compact from 'lodash/compact';

import { PositionStyleEnum } from '../../types';

import { PopoverCloseOnEsc } from './helpers/PopoverCloseOnEsc';

import {
  PopoverClickAwayMouseEventHandler,
  PopoverClickAwayTouchEventHandler,
  PopoverPlacement,
  preventOverflowModifier,
  sameWidthModifier
} from './popoverConstants';
import { VirtualElement } from '@popperjs/core/lib/types';

export interface PopoverProps {
  arrowClassName?: string;
  children?: ReactNode;
  className?: string;
  closeOnEsc?: boolean;
  closeOnOuterClick?: boolean;
  closePopover?: () => void;
  distanceOffset?: number;
  mouseEvent?: PopoverClickAwayMouseEventHandler | false;
  placement?: PopoverPlacement;
  positionStyle?: PositionStyleEnum;
  referenceElement?: HTMLElement | VirtualElement | null;
  touchEvent?: PopoverClickAwayTouchEventHandler | false;
  withArrow?: boolean;
  withoutCalculateMaxHeight?: boolean;
  withSameWidth?: boolean;
}

const Popover = ({
  arrowClassName,
  children,
  className,
  closeOnEsc = true,
  closeOnOuterClick = true,
  closePopover,
  distanceOffset = 10,
  mouseEvent,
  placement = PopoverPlacement.BOTTOM,
  positionStyle,
  referenceElement = null,
  touchEvent,
  withArrow,
  withoutCalculateMaxHeight,
  withSameWidth
}: PopoverProps) => {
  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const options = useMemo<Options>(() => {
    return {
      strategy: 'fixed',
      placement,
      modifiers: compact([
        {
          name: 'offset',
          options: {
            offset: [0, distanceOffset] // distanceOffset is distance from referenceElement
          }
        },
        withSameWidth ? sameWidthModifier : null,
        preventOverflowModifier,
        {
          name: 'maxSize',
          enabled: !withoutCalculateMaxHeight,
          phase: 'main',
          requiresIfExists: ['offset', 'preventOverflow', 'flip'],
          fn({ state, name, options }) {
            const overflow = detectOverflow(state, options);
            const { x, y } = state.modifiersData.preventOverflow || {
              x: 0,
              y: 0
            };
            const { width, height } = state.rects.popper;
            const [basePlacement] = state.placement.split('-');

            const widthProp = basePlacement === 'left' ? 'left' : 'right';
            const heightProp = basePlacement === 'top' ? 'top' : 'bottom';

            state.modifiersData[name] = {
              width: width - overflow[widthProp] - x,
              height: height - overflow[heightProp] - y
            };
          },
          options: {
            boundary: 'viewport',
            padding: 12
          }
        },
        {
          name: 'applyMaxSize',
          enabled: !withoutCalculateMaxHeight,
          phase: 'beforeWrite',
          requires: ['maxSize'],
          fn({ state }) {
            const { height } = state.modifiersData.maxSize;
            state.styles.popper.maxHeight = `${height}px`;
          }
        },
        withArrow
          ? {
              name: 'arrow',
              options: {
                element: arrowElement
              }
            }
          : null
      ])
    };
  }, [
    placement,
    distanceOffset,
    withoutCalculateMaxHeight,
    withArrow,
    arrowElement,
    withSameWidth
  ]);

  const { styles, attributes, state } = usePopper(
    referenceElement,
    popoverElement,
    options
  );

  const handleClickAway = useCallback<() => void>(() => {
    if (closeOnOuterClick) {
      closePopover?.();
    }
  }, [closeOnOuterClick, closePopover]);

  const popperStyles = positionStyle
    ? { ...styles.popper, position: positionStyle }
    : styles.popper;

  if (!children) {
    return null;
  }

  return (
    <ClickAwayListener
      mouseEvent={mouseEvent}
      onClickAway={handleClickAway}
      touchEvent={touchEvent}
    >
      <div
        {...attributes.popper}
        className={className}
        ref={setPopoverElement}
        style={popperStyles}
      >
        {withArrow ? (
          <div
            ref={setArrowElement}
            className={arrowClassName}
            style={styles.arrow}
            data-placement={state?.placement}
          />
        ) : null}
        {children}
        {closeOnEsc && closePopover ? (
          <PopoverCloseOnEsc closePopover={closePopover} />
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default Popover;
