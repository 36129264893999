import {
  BooleanFilter,
  CreatedAt,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  Currencies,
  DateFilterType,
  DateType,
  DeleteItemGqlQuery,
  ErrorMessage,
  FetchItemCacheKey,
  FetchItemError,
  FetchItemFetched,
  FetchItemGqlQuery,
  FetchItemIsPlaceholderData,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFetchingNextPage,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsHasNextItemsPage,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  IdFilterType,
  IDsFilter,
  IP,
  IsFetched,
  ItemGqlError,
  Locale,
  ModelBrand,
  MoneyType,
  NanoID,
  PayoneerAccountTypes,
  StringArrayFilter,
  StringFilter,
  TextFilterType,
  UpdatedAt,
  UpdateItemCacheKey,
  UpdateItemCacheKeys,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UUID,
  DeleteItemGqlStatus,
  DeleteItemCacheKeys,
  UpdateItemGqlError,
  NanoIdFilterType,
  UuidFilterType
} from '../../types';

import { ImageFile, ImageUUID } from '../images/imagesTypes';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';

import { RoleID, RoleName, RoleUUID } from '../roles/rolesTypes';
import { UpdateIndexQueryItemCacheAction } from '../common/hooks/base/reactQuery/useIndexQuery';
import { SaveSearchFilterFunc } from '../searchFilters/searchFiltersTypes';
import {
  FileAttachmentExt,
  FileAttachmentFile,
  FileAttachmentFileName,
  FileAttachmentID,
  FileAttachmentName,
  FileAttachmentNanoID,
  FileAttachmentSize,
  FileAttachmentType,
  FileAttachmentUUID
} from '../fileAttachments/fileAttachmentsTypes';
import { UpdateItemsGqlQuery } from '../../types/updateItemsTypes';
import {
  PermissionAction,
  PermissionUUID
} from '../permissions/permissionsTypes';
import {
  ProjectCreatedAt,
  ProjectID,
  ProjectImplementationDate,
  ProjectMembersFinanceRoleName,
  ProjectMembersFullName,
  ProjectMembersID,
  ProjectNanoID,
  ProjectUUID
} from '../projects/projectsTypes';
import { TaskID, TaskNanoID, TaskStartDate } from '../tasks/tasksTypes';
import { CategoryID } from '../categories/categoriesTypes';
import {
  TeamID,
  TeamName,
  TeamNanoID,
  TeamPreferredPaymentMethod,
  TeamTerms,
  TeamType,
  TeamUseLdApiAt,
  TeamUUID
} from '../teams/teamsTypes';
import {
  OperationType,
  OperationCreatedAt,
  OperationBody,
  OperationSubject
} from '../operations/operationsTypes';

export type UserID = ID;
export type UserIDs = UserID[];
export type UserUUID = UUID;
export type UserNanoID = ModelBrand<NanoID, 'UserNanoID'>;
export type UserFavorite = boolean;
export type UserName = string;
export type UserDebtLimit = number;
export type UserNameEn = string;
export type UserNameUk = string;
export type UserSurname = string;
export type UserSurnameEn = string;
export type UserSurnameUk = string;
export type UserFullName = string;
export type UserPayoneerCheckoutAddress = boolean;
export type UserProvidedFullName = string;
export type UserProvidedFullNameEn = string;
export type UserProvidedFullNameUk = string;
export type UserRoleId = RoleID;
export type UserRoleUUID = RoleUUID;
export type UserRoleName = RoleName;
export type UserFinanceRoleId = RoleID;
export type UserFinanceRoleName = RoleName;
export type UserClient = boolean;
export type UserClientBalance = number;
export type UserClientRateMedian = number;
export type UserClientRateAverage = number;
export type UserTestProjectID = ProjectID;
export type UserTestProjectUUID = ProjectUUID;
export type UserTestProjectNanoID = ProjectNanoID;
export type UserTestProjectCreatedAt = ProjectCreatedAt;
export type UserTestProjectImplementationDate = ProjectImplementationDate;
export type UserTestProjectTaskStartDate = TaskStartDate;
export type UserTestProjectMembersID = ProjectMembersID;
export type UserTestProjectMembersFullName = ProjectMembersFullName;
export type UserTestProjectMembersFinanceRoleName =
  ProjectMembersFinanceRoleName;
export type UserTestTaskId = TaskID;
export type UserTestTaskNanoId = TaskNanoID;
export type UserTheeDServicesId = CategoryID;
export type UserEstimationAmount = number;
export type UserEstimationDate = DateType;

export type UserAirTableApiKey = string;
export type UserAirTableBaseId = string;
export type UserAirTableEmail = string;
export type UserAirTablePassword = string;
export type UserAirTableTableName = string;

export type UserAddress = string;
export type UserWorkerBalance = number;
export type UserBlockedTermsAcceptedAt = UpdatedAt;
export type UserCreatedAt = CreatedAt;
export type UserUpdatedAt = UpdatedAt;
export type UserEmail = string;
export type UserPassword = string;
export type UserCompany = string;
export type UserLocale = Locale;
export type UserPhone = string;
export type UserLocation = string;
export type UserFirstPaymentDate = DateType | null;
export type UserLastPaymentDate = DateType | null;
export type UserLastClientStatusUpdatedAt = DateType;
export type UserLastMessageDate = DateType | null;
export type UserDateOfBirth = string;
export type UserTimeZone = string;
export type UserNDA = boolean;
export type UserCity = string;
export type UserPosition = UserSelectFieldValue;
export type UserPositionID = ID;
export type UserBalanceAmount = number;
export type UserDebt = number;
export type UserClientDebt = number;
export type UserRating = number;
export type UserRatio = number;
export type UserTotalAmount = number;
export type UserTurnAroundTime = string;
export type UserTurnAroundTimeMonthly = string;
export type UserPaidProjectsCount = number;
export type UserProjectsCount = number;
export type UserProjectMembersCount = number;
export type UserTaskMembersCount = number;
export type UserAverageTasksMark = number;
export type UserDebitAccount = number;
export type UserBonusAccount = number;
export type UserCreditAccount = number;
export type UserPrepaidBalanceAmount = number;
export type UserClientStatus = UserClientStatusesEnum;
export type UserClientSigmaPayments = number;
export type UserWorkerRateAverage = number;
export type UserWorkerRateMedian = number;
export type UserCurrency = Currencies;
export type UserMyIp = IP;
export type UserBlocked = boolean;
export type UserWithBlocked = boolean;
export type UserSelectFieldValue = { id: ID };
export type UserCategory = UserSelectFieldValue;
export type UserCompanySize = UserSelectFieldValue;
export type UserNoGo = boolean;
export type UserBusinessEmail = boolean;
export type UserSite = string;
export type UserManualClientStatus = string;
export type UserIsMentor = boolean;
export type UserIntegration = UserSelectFieldValue;
export type UserSubCategories = UserSelectFieldValue[];
export type UserSource = UserSelectFieldValue;
export type UserQualifications = UserSelectFieldValue[];
export type UserRealDeclineReasons = UserSelectFieldValue[];
export type UserReferer = string;
export type UserRatingCorrectedManually = boolean;
export type UserSpecializations = UserSelectFieldValue[];
export type UserSystemAccount = boolean;
export type UserTaskRoleDebt = number;
export type UserProficiencyLevel = UserSelectFieldValue;
export type UserWorkExperience = string;
export type UserAdditionalInformation = string;
export type UserRateEducationPoint = number;
export type UserProjectManager = UserType;
export type UserProjectManagersFollowerFullName = UserFullName;
export type UserMentorFullName = UserFullName;
export type UserClientManagerFullName = UserFullName;
export type UserMentor = UserType;
export type UserWildlyImportantGoalsCount = number;
export type UserGroups = UserSelectFieldValue[];
export type UserDiscount = number;
export type UserPreferableCurrency = Currencies;
export type UserExpectedPaymentDate = DateType;
export type UserExpectedPaymentAmount = number;
export type UserDeferredPayment = number;
export type UserHoldRate = number;
export type UserAgreedHourlyRate = MoneyType;
export type UserPayoneerId = ID;
export type UserPayoneerIdPe = ID;
export type UserPayoneerIdAvltd = ID;
export type UserPayoneerIdAvltdUk = ID;
export type UserPayoneerIdAvLlc = ID;
export type UserPayoneerIdPeAvltd = ID;
export type UserPayoneerIdPeAvLlc = ID;
export type UserPayoneerType = PayoneerAccountTypes;
export type UserPaymentTypes = UserSelectFieldValue[];
export type UserFinanceTags = UserSelectFieldValue[];
export type UserGroupID = ID;
export type UserGroupIds = UserGroupID[];
export type UserGroupUUID = UUID;
export type UserGroupUUIDs = UserCellUUID[];
export type UserGroupName = string;
export type UserCellId = ID;
export type UserCellIds = UserCellId[];
export type UserCellUUID = UUID;
export type UserCellUUIDs = UserCellUUID[];
export type UserCellName = string;
export type UserSocialNetworks = string;
export type UserFacebook = string;
export type UserLinkedIn = string;
export type UserLeadCreationDate = DateType;
export type UserUrlId = ID;
export type UserCdn = string;
export type UserTotalHoursDay = number;
export type UserCurrentTeamUUID = TeamUUID;
export type UserCurrentTeamID = TeamID;
export type UserCurrentTeamNanoID = TeamNanoID;
export type UserCurrentTeamUseLdApiAt = TeamUseLdApiAt;
export type UserCurrentTeamName = TeamName;
export type UserCurrentTeamPreferredPaymentMethod = TeamPreferredPaymentMethod;
export type UserCurrentTeamType = TeamType;
export type UserCurrentTeamTerms = TeamTerms;
export type UserUserInTeamUUID = TeamUUID;
export type UserUserInTeamID = TeamID;
export type UserUserInTeamNanoID = TeamNanoID;
export type UserUserInTeamName = TeamName;
export type UserUserInTeamType = TeamType;
export type UserLastOperationType = OperationType;
export type UserLastOperationCreatedAt = OperationCreatedAt;
export type UserLastOperationBody = OperationBody;
export type UserLastOperationSubject = OperationSubject;
export type UserPayoneerPayoutsBlocked = boolean;

export type UserDocumentPage = {
  ext: FileAttachmentExt;
  file: FileAttachmentFile;
  fileName: FileAttachmentFileName;
  id: FileAttachmentID;
  name: FileAttachmentName;
  nanoId: FileAttachmentNanoID;
  size: FileAttachmentSize;
  type: FileAttachmentType;
  uuid: FileAttachmentUUID;
};
export type UserDocumentPages = UserDocumentPage[];

export type UserImageUUID = ImageUUID;
export type UserImageFile = ImageFile;

export type UserPermissionsUUID = PermissionUUID;
export type UserPermissionsAction = PermissionAction;

export type UserTermsAcceptedAt = DateType;

export type CreateBillInTaskGqlQuery = CreateItemGqlQuery;
export type AddCellsToUsersGqlQuery = UpdateItemsGqlQuery;
export type AddGroupsToUsersGqlQuery = UpdateItemsGqlQuery;
export type BlockUsersGqlQuery = UpdateItemsGqlQuery;
export type UnblockUsersGqlQuery = UpdateItemsGqlQuery;

export enum UserClientStatusesEnum {
  NEW = 'new',
  ACTIVE = 'active',
  PAUSE = 'paused',
  DECLINED = 'declined',
  REAL_DECLINE = 'real_decline',
  NO_PAYMENTS = 'no_payments'
}
export const enum UserRoles {
  ADMIN = 'admin',
  MANAGER = 'manager',
  WORKER = 'worker',
  CONTENT = 'content',
  SUPPORT = 'support',
  ART_DIRECTOR = 'art_director',
  SALE_MANAGER = 'sale_manager',
  UPSALE_MANAGER = 'upsale_manager',
  SYSTEM = 'system',
  ARCHIVIZER = 'archivizer',
  CLIENT = 'client',
  BASE = 'base',
  REMOTE_WORKER = 'remote_worker',
  SUPERADMIN = 'superadmin',
  FINANCE = 'finance',
  STUDENT = 'student',
  MENTOR = 'mentor',
  PROJECT_MANAGER = 'project_manager',
  QA = 'qa',
  DEVELOPER = 'developer',
  HR = 'hr',
  CLIENT_COZY = 'client_cozy',
  CLIENT_NOFINANCE = 'client_nofinance',
  MANAGER_COZY = 'manager_cozy',
  MENTOR_PM = 'mentor_pm',
  TEMP_BLOCKED = 'temp_blocked',
  ANALYST = 'analyst',
  PROJECT_UPSALE_MANAGER = 'project_upsale_manager',
  AI_CONTENT = 'ai_content',
  WHITEBOARD = 'whiteboard',
  CLIENT_TEST_PAYONEER = 'client_test_payoneer',
  WORKER_FIN = 'worker_fin',
  DD_FIVERR = 'dd_fiverr',
  BLOCKED = 'blocked',
  PROJECT_SUPPORT_MT = 'project_support_mt',
  PROJECT_SUPPORT = 'project_support',
  INBOX = 'inbox'
}

export const enum UserCdnEnum {
  USA = 'usa',
  EU = 'eu'
}

export const enum UserFields {
  ADDITIONAL_EMAIL_1 = 'additionalEmail1',
  ADDITIONAL_EMAIL_2 = 'additionalEmail2',
  ADDITIONAL_INFORMATION = 'additionalInformation',
  ADDRESS = 'address',
  BUSINESS_EMAIL = 'businessEmail',
  CATEGORY = 'category',
  CELLS = 'cells',
  CELL_IDS = 'cellIds',
  CITY = 'city',
  CLIENT_STATUS = 'clientStatus',
  CO_PAGES = 'coPages',
  COLD_SOURCE = 'coldSource',
  COMPANY_SIZE = 'companySize',
  COWORKING_PAGES = 'coworkingPages',
  CREATED_AT = 'createdAt',
  FACEBOOK = 'facebook',
  FULL_NAME = 'fullName',
  EMAIL = 'email',
  ID = 'id',
  IS_MENTOR = 'isMentor',
  ITN_PAGES = 'itnPages',
  INTEGRATION = 'integration',
  GROUPS = 'groups',
  GROUP_IDS = 'groupIds',
  RATE_EDUCATION_POINT = 'rateEducationPoint',
  LEAD_CREATION_DATE = 'leadCreationDate',
  LINKED_IN = 'linkedIn',
  LOCALE = 'locale',
  MANUAL_CLIENT_STATUS = 'manualClientStatus',
  MARKETING_SOURCE = 'marketingSource',
  MENTOR = 'mentor',
  MENTOR_ID = 'mentorId',
  NAME = 'name',
  NAME_EN = 'nameEn',
  NAME_UK = 'nameUk',
  NDA = 'nda',
  NDA_PAGES = 'ndaPages',
  NO_GO = 'noGo',
  PA_PAGES = 'paPages',
  HOLD_RATE = 'holdRate',
  DEBT_LIMIT = 'debtLimit',
  RATING = 'rating',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  CURRENT_PASSWORD = 'currentPassword',
  PASSPORT_PAGES = 'passportPages',
  PE_PAGES = 'pePages',
  PHONE = 'phone',
  POSITION = 'position',
  POSITION_ID = 'positionId',
  PROFICIENCY_LEVEL = 'proficiencyLevel',
  PROFICIENCY_LEVEL_ID = 'proficiencyLevelId',
  PROJECT_MANAGER = 'projectManager',
  PROJECT_MANAGER_ID = 'projectManagerId',
  PROVIDED_FULL_NAME = 'providedFullName',
  PROVIDED_FULL_NAME_UK = 'providedFullNameUk',
  PROVIDED_FULL_NAME_EN = 'providedFullNameEn',
  QUALIFICATIONS = 'qualifications',
  QUALIFICATION_IDS = 'qualificationIds',
  REAL_DECLINE_REASONS = 'realDeclineReasons',
  REFERER = 'referer',
  RATIO = 'ratio',
  ROLES = 'roles',
  ROLE_IDS = 'roleIds',
  SCHOOL_AGREEMENT_PAGES = 'schoolAgreementPages',
  SITE = 'site',
  SOURCE = 'source',
  SOCIAL_NETWORKS = 'socialNetworks',
  SPECIALIZATIONS = 'specializations',
  SPECIALIZATION_IDS = 'specializationIds',
  SUB_CATEGORIES = 'subCategories',
  SURNAME = 'surname',
  SURNAME_EN = 'surnameEn',
  SURNAME_UK = 'surnameUk',
  UPDATED_AT = 'updatedAt',
  UUID = 'uuid',
  URL = 'url',
  WILDLY_IMPORTANT_GOALS_COUNT = 'wildlyImportantGoalsCount',
  // Finance info
  FIRST_PAYMENT_DATE = 'firstPaymentDate',
  LAST_PAYMENT_DATE = 'lastPaymentDate',
  DISCOUNT = 'discount',
  CURRENCY = 'currency',
  AGREED_HOURLY_RATE = 'agreedHourlyRate',
  PAYONEER_ID = 'payoneerId',
  PAYONEER_ID_PE = 'payoneerIdPe',
  PAYONEER_ID_AVLTD_UK = 'payoneerIdAvltdUk',
  PAYONEER_ID_AV_LLC = 'payoneerIdAvLlc',
  PAYONEER_ID_PE_AVLTD = 'payoneerIdPeAvltd',
  PAYONEER_ID_PE_AV_LLC = 'payoneerIdPeAvLlc',
  PAYONEER_ID_TYPE = 'payoneerIdType',
  DEFERRED_PAYMENT = 'deferredPayment',
  EXPECTED_PAYMENT_DATE = 'expectedPaymentDate',
  EXPECTED_PAYMENT_AMOUNT = 'expectedPaymentAmount',
  PAYMENT_TYPES = 'paymentTypes',
  FINANCE_TAGS = 'financeTags',
  FINANCE_TAG_IDS = 'financeTagIds',
  FINANCE_ROLE = 'financeRole',
  RATING_CORRECTED_MANUALLY = 'ratingCorrectedManually',
  SYSTEM_ACCOUNT = 'systemAccount',
  CLIENT_MANAGER = 'clientManager',
  SALES_MANAGER = 'salesManager',
  CDN = 'cdn',
  // Marketing info - Test Project info
  TEST_PROJECT = 'testProject',
  TEST_TASK = 'testTask',
  THREE_D_SERVICES = 'threeDServices',
  ESTIMATION_AMOUNT = 'estimationAmount',
  ESTIMATION_DATE = 'estimationDate',
  FINANCE_ROLE_ID = 'financeRoleId',
  // Documents
  CO_PAGE_IDS = 'coPageIds',
  COWORKING_PAGE_IDS = 'coworkingPageIds',
  ITN_PAGE_IDS = 'itnPageIds',
  NDA_PAGE_IDS = 'ndaPageIds',
  PA_PAGE_IDS = 'paPageIds',
  PASSPORT_PAGE_IDS = 'passportPageIds',
  PE_PAGE_IDS = 'pePageIds',
  SCHOOL_AGREEMENT_PAGE_IDS = 'schoolAgreementPageIds',
  // AirTable
  AIR_TABLE_EMAIL = 'airTableEmail',
  AIR_TABLE_PASSWORD = 'airTablePassword',
  AIR_TABLE_API_KEY = 'airTableApiKey',
  AIR_TABLE_BASE_ID = 'airTableBaseId',
  AIR_TABLE_TABLE_NAME = 'airTableTableName'
}

export type UserCreatedAtFilter = DateFilterType;
export type UserClientStatusFilter = StringFilter;
export type UserFinanceRoleIdFilter = IdFilterType;
export type UserNameFilter = TextFilterType;
export type UserEmailFilter = TextFilterType;
export type UserFullNameFilter = StringFilter;
export type UserGroupIdsFilter = IDsFilter;
export type UserRoleIdsFilter = IDsFilter;
export type UserFollowerIdsFilter = IDsFilter;
export type UserMentorIdFilter = IdFilterType;
export type UserUpsalerIdFilter = IdFilterType;
export type UserTeamIdsFilter = IDsFilter;
export type UserIdFilter = IdFilterType;
export type UserIsMentorFilter = BooleanFilter;
export type UserBlockedFilter = BooleanFilter;
export type UserWithBlockedFilter = BooleanFilter;
export type UserWithoutBlockedFilter = BooleanFilter;
export type UserFavoriteFilter = BooleanFilter;
export type UserCellIdsFilter = IDsFilter;
export type UserScopeFilter = StringArrayFilter;

export interface FetchUsersFilters {
  id?: UserIdFilter;
  createdAt?: UserCreatedAtFilter;
  financeRoleId?: UserFinanceRoleIdFilter;
  name?: UserNameFilter;
  email?: UserEmailFilter;
  fullName?: UserFullNameFilter;
  groupIds?: UserGroupIdsFilter;
  roleIds?: UserRoleIdsFilter;
  teamIds?: UserTeamIdsFilter;
  isMentor?: UserIsMentorFilter;
  blocked?: UserBlockedFilter;
  withBlocked?: UserWithBlockedFilter;
  withoutBlocked?: UserWithoutBlockedFilter;
  clientStatus?: UserClientStatusFilter;
  favorite?: UserFavoriteFilter;
  cellIds?: UserCellIdsFilter;
  followerIds?: UserFollowerIdsFilter;
  mentorId?: UserMentorIdFilter;
  scope?: UserScopeFilter;
  upsalerId?: UserUpsalerIdFilter;
}

export interface FetchFinUsersFilters {
  blocked?: boolean;
  createdAt?: DateFilterType;
  email?: TextFilterType;
  id?: IdFilterType;
  messagesInProjectNanoId?: NanoIdFilterType;
  messagesInTaskNanoId?: NanoIdFilterType;
  name?: TextFilterType;
  nanoId?: NanoIdFilterType;
  roleIds?: ID[];
  surname?: TextFilterType;
  updatedAt?: DateFilterType;
  uuid?: UuidFilterType;
}

export type FetchUsersFiltersFunc = (nextFilters: FetchUsersFilters) => void;
export type UsersCreateSearchFiltersFunc =
  SaveSearchFilterFunc<FetchUsersFilters>;

export const enum FetchUsersScopeFilter {
  PROJECT_MANAGERS = 'project_managers',
  UPSALE_MANAGERS = 'upsale_managers',
  PROJECT_UPSALE_MANAGERS = 'project_upsale_managers',
  SALES_MANAGER = 'sale_managers',
  MENTORS = 'mentors',
  CLIENTS = 'clients'
}

export const enum FetchUsersSortableFields {
  ID = 'id',
  NAME = 'name',
  FULL_NAME = 'provided_full_name',
  RATE_EDUCATION_POINT = 'rate_education_point',
  WORKER_RATE_AVERAGE = 'worker_rate_average',
  CREATED_AT = 'createdAt',
  EXPERIENCE = 'work_experience'
}

export const enum FetchUsersFilterFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  CLIENT_STATUS = 'clientStatus',
  FINANCE_ROLE_ID = 'financeRoleId',
  FOLLOWER_IDS = 'followerIds',
  EMAIL = 'email',
  FULL_NAME = 'fullName',
  GROUP_IDS = 'groupIds',
  ROLE_IDS = 'roleIds',
  CELL_IDS = 'cellIds',
  TEAM_IDS = 'teamIds',
  IS_MENTOR = 'isMentor',
  BLOCKED = 'blocked',
  FAVORITE = 'favorite',
  WITH_BLOCKED = 'withBlocked',
  WITHOUT_BLOCKED = 'withoutBlocked',
  UPSALER_ID = 'upsalerId.in',
  MENTOR_ID = 'mentorId.in'
}

export const enum FetchUsersSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export const enum AddCellsToUsersFields {
  CELL_UUIDS = 'cellUuids'
}

export const enum AddGroupsToUsersFields {
  GROUP_UUIDS = 'groupUuids'
}

export type FetchUsersGqlQuery = FetchItemsGqlQuery;
export type FetchUserGqlQuery = FetchItemGqlQuery;
export type CreateUserGqlQuery = CreateItemGqlQuery;
export type UpdateUserGqlQuery = UpdateItemGqlQuery;
export type DeleteUserAccountGqlQuery = DeleteItemGqlQuery;
export type DeleteCurrentUserAccountGqlQuery = DeleteItemGqlQuery;

export type DeleteUserAccountGqlStatus = DeleteItemGqlStatus;
export type DeleteCurrentUserAccountGqlStatus = DeleteItemGqlStatus;
export type DeleteUserAccountCacheKeys = DeleteItemCacheKeys;
export type DeleteCurrentUserAccountCacheKeys = DeleteItemCacheKeys;
export type DeleteUserGqlQuery = DeleteItemGqlQuery;
export type SubmitFileAttachmentsToTaskProfileGqlQuery = CreateItemGqlQuery;

export type UpdateUserGqlStatus = UpdateItemGqlStatus;
export type SubmitFileAttachmentsToTaskProfileGqlStatus = CreateItemGqlStatus;
export type UserGqlError = ItemGqlError;
export type UserGqlErrors = UserGqlError[];

export type FetchUsersCacheKey = FetchItemsCacheKey;
export type FetchUsersCacheKeys = FetchUsersCacheKey[];
export type FetchUserCacheKey = FetchItemCacheKey;
export type FetchUserCacheKeys = FetchUserCacheKey[];
export type FetchUserProfileCacheKey = FetchItemCacheKey;

export type FetchUsersFetched = FetchItemsFetched;
export type FetchUsersErrorMessage = FetchItemsErrorMessage;
export type FetchUsersIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchUsersPage = FetchItemsPage;
export type FetchUsersLimit = FetchItemsLimit;
export type FetchUsersSort = FetchItemsSort; // FetchUsersSortTypes[];
export type FetchUsersTotalCount = FetchItemsTotalCount;
export type FetchUsersFilterUsers = FetchItemsFilterItems<FetchUsersFilters>;
export type FetchUsersClearUsersFilters = FetchItemsClearItemsFilters;
export type FetchUsersSortUsers = FetchItemsSortItems;
export type FetchUsersPaginateUsers = FetchItemsPaginateItems;
export type FetchUsersPrefetchUsers = FetchItemsPrefetchItems;
export type FetchUsersFetchingNextPage = FetchItemsFetchingNextPage;
export type FetchUsersHasNextItemsPage = FetchItemsHasNextItemsPage;

export type FetchUserFetched = FetchItemFetched;
export type FetchUserIsPlaceholderData = FetchItemIsPlaceholderData;
export type FetchUserErrorMessage = ErrorMessage;
export type FetchUserError = FetchItemError;

export type UpdateUserPasswordByCurrentPasswordGqlQuery = UpdateItemGqlQuery;
export type UpdateUserPasswordByCurrentPasswordGqlStatus = UpdateItemGqlStatus;
export type UpdateUserPasswordByCurrentPasswordGqlError = UpdateItemGqlError;

export type UpdateUserPasswordWithoutCurrentPasswordGqlQuery =
  UpdateItemGqlQuery;
export type UpdateUserPasswordWithoutCurrentPasswordGqlStatus =
  UpdateItemGqlStatus;
export type UpdateUserPasswordWithoutCurrentPasswordGqlError =
  UpdateItemGqlError;

export type FetchUsersChecked = Checked;
export type FetchUsersCheckedHash = CheckedHashItem;
export type FetchUsersOnSetCheckedIds = OnSetCheckedIds;
export type FetchUsersCheckedAll = CheckedAll;
export type FetchUsersOnCheckAll = OnCheckAll;

export type UpdateUserDocumentsGqlQuery = UpdateItemGqlQuery;
export type UpdateUserDocumentsCacheKey = UpdateItemCacheKey;
export type UpdateUserDocumentsCacheKeys = UpdateItemCacheKeys;

export type UnblockUserGqlQuery = UpdateItemsGqlQuery;
export type UnblockUserGqlStatus = UpdateItemGqlStatus;
export type UnblockUserGqlError = ItemGqlError;
export type UnblockUserGqlErrors = UnblockUserGqlError[];

export type BlockUserGqlQuery = UpdateItemsGqlQuery;
export type BlockUserGqlStatus = UpdateItemGqlStatus;
export type BlockUserGqlError = ItemGqlError;
export type BlockUserGqlErrors = BlockUserGqlError[];

export type UserTogglePayoneerPayoutsBlockedGqlQuery = UpdateItemsGqlQuery;
export type UserTogglePayoneerPayoutsBlockedGqlStatus = UpdateItemGqlStatus;
export type UserTogglePayoneerPayoutsBlockedGqlError = ItemGqlError;
export type UserTogglePayoneerPayoutsBlockedGqlErrors =
  UserTogglePayoneerPayoutsBlockedGqlError[];

// Deprecated

export type UserFetched = IsFetched;

export type UpdateUserCacheAction = string;
export type UpdateUserCacheKeys = UpdateItemCacheKeys;

export interface UserType {
  id: ID;
  uuid: UUID;
  // email: string;
  locale: Locale;
  name: UserName;
  surname?: UserSurname;
  fullName?: UserFullName;
  image: {
    file: UserImageFile;
  };
}

export type UserProfileCell = {
  id: UserCellId;
  name?: UserCellName;
};
export type UserProfileCells = UserProfileCell[];
export interface UserProfileType {
  // Basic Info
  id: UserID;
  uuid: UserUUID;
  nanoId: UserNanoID;
  fullName: UserFullName;
  providedFullName: UserFullName;
  providedFullNameEn: UserFullName;
  providedFullNameUk: UserFullName;
  name: UserName;
  debtLimit: UserDebtLimit;
  surname: UserSurname;
  nameEn: UserNameEn;
  surnameEn: UserSurnameEn;
  nameUk: UserNameUk;
  surnameUk: UserSurnameUk;
  salesManager: { id: UserID };
  systemAccount: UserSystemAccount;
  rating: UserRating;
  blocked: UserBlocked;
  email: UserEmail;
  phone: UserPhone;
  nda: UserNDA;
  client: UserClient;
  image: { file: UserImageFile };
  // Dashboard
  turnAroundTimeMonthly: UserTurnAroundTimeMonthly;
  clientRateAverage: UserClientRateAverage;
  workerRateAverage: UserWorkerRateAverage;
  clientRateMedian: UserClientRateMedian;
  workerRateMedian: UserWorkerRateMedian;
  // Marketing Info
  address: UserAddress;
  city: UserCity;
  clientStatus: UserClientStatus;
  clientManager: { id: UserID };
  cdn: string;
  holdRate: UserHoldRate;
  company: UserCompany;
  category: UserCategory;
  companySize: UserCompanySize;
  noGo: UserNoGo;
  site: UserSite;
  socialNetworks: UserSocialNetworks;
  facebook: UserFacebook;
  linkedIn: UserLinkedIn;
  leadCreationDate: UserLeadCreationDate;
  manualClientStatus: UserManualClientStatus;
  integration: UserIntegration;
  subCategories: UserSubCategories;
  businessEmail: UserBusinessEmail;
  additionalEmail1: UserEmail;
  additionalEmail2: UserEmail;
  source: UserSource;
  marketingSource: UserSource;
  coldSource: UserSource;
  realDeclineReasons: UserRealDeclineReasons;
  referer: UserReferer;
  ratio: UserRatio;
  roles: {
    id: UserRoleId;
  }[];
  ratingCorrectedManually: UserRatingCorrectedManually;
  // Marketing Info Test Project Info
  testProject: {
    id: UserTestProjectID;
    uuid: UserTestProjectUUID;
    nanoId: UserTestProjectNanoID;
    createdAt: UserTestProjectCreatedAt;
    implementationDate: UserTestProjectImplementationDate;
    tasks: {
      startDate: UserTestProjectTaskStartDate;
    }[];
    members: {
      id: UserTestProjectMembersID;
      fullName: UserTestProjectMembersFullName;
      financeRoleName: UserTestProjectMembersFinanceRoleName;
    }[];
  };
  testTask: {
    id: UserTestTaskId;
    nanoId: UserTestTaskNanoId;
  };
  threeDServices: {
    id: UserTheeDServicesId;
  }[];
  estimationAmount: UserEstimationAmount;
  estimationDate: UserEstimationDate;

  // Description
  position: UserPosition;
  proficiencyLevel: UserProficiencyLevel;
  specializations: UserSpecializations;
  qualifications: UserQualifications;
  workExperience: UserWorkExperience;
  projectManager: UserProjectManager;
  mentor: UserMentor;
  isMentor: UserIsMentor;
  cells: UserProfileCells;
  additionalInformation: UserAdditionalInformation;
  rateEducationPoint: UserRateEducationPoint;
  wildlyImportantGoalsCount: UserWildlyImportantGoalsCount;
  groups: UserGroups;
  // Documents
  coPages: UserDocumentPages;
  coworkingPages: UserDocumentPages;
  itnPages: UserDocumentPages;
  ndaPages: UserDocumentPages;
  paPages: UserDocumentPages;
  passportPages: UserDocumentPages;
  pePages: UserDocumentPages;
  schoolAgreementPages: UserDocumentPages;
  // Finance info
  firstPaymentDate: UserFirstPaymentDate;
  lastPaymentDate: UserLastPaymentDate;
  discount: UserDiscount;
  currency: UserPreferableCurrency;
  expectedPaymentDate: UserExpectedPaymentDate;
  expectedPaymentAmount: UserExpectedPaymentAmount;
  paymentTypes: UserPaymentTypes;
  financeTags: UserFinanceTags;
  financeRole: { id: UserRoleId };
  deferredPayment: UserDeferredPayment;
  agreedHourlyRate: UserAgreedHourlyRate;
  payoneerId: UserPayoneerId;
  payoneerIdPe: UserPayoneerIdPe;
  payoneerIdAvltd: UserPayoneerIdAvltd;
  payoneerIdAvLlc: UserPayoneerIdAvLlc;
  payoneerIdAvltdUk: UserPayoneerIdAvltdUk;
  payoneerIdPeAvltd: UserPayoneerIdPeAvltd;
  payoneerIdPeAvLlc: UserPayoneerIdPeAvLlc;
  urlId: UserUrlId;
  payoneerIdType: UserPayoneerType;
  currentTeam: {
    id: UserCurrentTeamID;
    name: UserCurrentTeamName;
    nanoId: UserCurrentTeamNanoID;
  };
  userInTeams: {
    uuid: UserUserInTeamUUID;
    name: UserUserInTeamName;
    nanoId: UserUserInTeamNanoID;
  }[];
  // Personalization
  locale: UserLocale;
  // Airtable
  airTableApiKey: UserAirTableApiKey;
  airTableBaseId: UserAirTableBaseId;
  airTableEmail: UserAirTableEmail;
  airTablePassword: UserAirTablePassword;
  airTableTableName: UserAirTableTableName;
}

export interface UserFinanceType {
  id: UserID;
  uuid: UserUUID;
  balanceAmount: UserBalanceAmount;
  bonusAccount: UserBonusAccount;
  creditAccount: UserCreditAccount;
  debitAccount: UserDebitAccount;
  prepaidBalanceAmount: UserPrepaidBalanceAmount;
}

export type UsersType = UserType[];

export type UsersTabType = 'clients' | 'workers' | null;

export type UsersTabsType = UsersTabType[];

export type UpdatePaginatedUsersItemCacheAction<ItemType> =
  UpdateIndexQueryItemCacheAction<ItemType>;
