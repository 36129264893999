import { ClientError } from 'graphql-request';
import includes from 'lodash/includes';

function retryAuthFunction(failureCount: number, error?: ClientError | Error) {
  {
    return !(
      includes(error?.message, 'Authorized users only') ||
      error?.message === 'Network request failed' ||
      failureCount >= 3
    );
  }
}

export default retryAuthFunction;
