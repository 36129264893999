import React from 'react';

import { IconProps } from '../types';

function FullscreenExitIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M2,15h3v3h2v-5H2V15z M5,5H2v2h5V2H5V5z M13,18h2v-3h3v-2h-5V18z M15,5V2h-2v5h5V5H15z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FullscreenExitIcon;
