import { AxiosError } from 'axios';

import { IconsEnum } from '../assets/icons/types';
import { words } from '../locales/keys';

export type BaseBrand<K, T extends string> = K & { readonly __baseBrand: T };
export type ModelBrand<K, T extends string> = K & { readonly __modelBrand: T };

export type MayBe<T> = T | null;

export type IP = string;
export type ID = string;
export type UUID = BaseBrand<string, 'UUID'>;
export type NanoID = BaseBrand<string, 'NanoID'>;
export type DateType = string;
export type MoneyType = number;
export type PercentageType = number;
export type CreatedAt = DateType;
export type UpdatedAt = DateType;
export type DeletedAt = DateType | null;
export type IndexedAt = DateType | null;
export type DefaultAt = DateType | null;

export type IsLoading = boolean;
export type IsDisabled = boolean;
export type IsFetched = boolean;
export type IsFetching = boolean;
export type IsFetchingNextPage = boolean;
export type IsOptimisticUpdate = boolean;
export type ErrorMessage = string | undefined | null;
export type ErrorMessages = ErrorMessage[];
export type SuccessMessage = string | null;
export type IsPlaceholderData = boolean;
export type HasNextPage = boolean;
export type IsSelected = boolean;

export type ClassName = string;
export type I18nText = string;

export type FormID = string;

export type FileUrl = string;
export type PathUrl = string;
export type FileName = string;
export type FileSize = BaseBrand<number, 'FileSize'>;
export type DeviceNanoId = BaseBrand<string, 'DeviceNanoId'>;

export type ImageDimensions = {
  width: number;
  height: number;
};

export type BreadcrumbsUrl = {
  i18nText?: I18nText;
  pathUrl: PathUrl;
  icon?: IconsEnum;
  tooltipI18nText?: I18nText;
};
export type BreadcrumbsUrls = BreadcrumbsUrl[];

export type HexColor = BaseBrand<string, 'HexColor'>;

export type Locale = 'en' | 'ru' | 'uk';

export const enum AppCrmNames {
  ARCHIVIZER = 'Archivizer',
  SMASHING_CGI = 'SmashingCGI',
  MARKET_THRIVE = 'MarketThrive'
}

export const enum Currencies {
  USD = 'USD',
  RUB = 'RUB',
  UAH = 'UAH',
  EUR = 'EUR',
  GBP = 'GBP',
  AUD = 'AUD'
}

export const enum CurrenciesSymbols {
  USD = '$',
  RUB = '₽',
  UAH = '₴',
  EUR = '€',
  GBP = '£',
  AUD = 'AU$'
}

export const currenciesSymbols = {
  USD: CurrenciesSymbols.USD,
  RUB: CurrenciesSymbols.RUB,
  UAH: CurrenciesSymbols.UAH,
  EUR: CurrenciesSymbols.EUR,
  GBP: CurrenciesSymbols.GBP,
  AUD: CurrenciesSymbols.AUD
};

export const enum PayoneerAccountTypes {
  PAYONEER_ID = 'payoneer_id',
  PAYONEER_ID_PE = 'payoneer_id_pe',
  PAYONEER_ID_AVLTD = 'payoneer_id_avltd',
  PAYONEER_ID_AV_LLC = 'payoneer_id_av_llc',
  PAYONEER_ID_AVLTD_UK = 'payoneer_id_avltd_uk',
  PAYONEER_ID_PE_AVLTD = 'payoneer_id_pe_avltd',
  PAYONEER_ID_PE_AV_LLC = 'payoneer_id_pe_av_llc',
  NONE = 'none'
}

export const enum FieldIcons {
  DOLLAR = '$'
}

export const enum TableViewType {
  DEFAULT = 'default',
  SLIM = 'slim'
}

export const enum Units {
  CM = 'cm',
  IN = 'in'
}

export const unitsConfiguration = {
  [Units.CM]: {
    i18nLabel: words.units.cm,
    multiplier: 1
  },
  [Units.IN]: {
    i18nLabel: words.units.in,
    multiplier: 0.393701
  }
};

export type ReadApiError = AxiosError<{
  error: {
    statusCode: number;
    message: string;
  };
}>;

export type GADataType = {
  dataGa?: string;
};
