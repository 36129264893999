import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/nextjs';
import startsWith from 'lodash/startsWith';

import AuthenticatedUser from './AuthenticatedUser';

import { UserInfo } from './useAuth.types';

import {
  FETCH_FIN_CURRENT_USER,
  FetchFinCurrentUserQueryResponse
} from '../../queries/fetchFinCurrentUser.query';

import { useAuthRedirects } from '../useAuthRedirects';
import { useChangeAppLocale } from '../../../common/hooks/useChangeAppLocale';
import { useFetchFinCurrentUser } from '../useFetchFinCurrentUser';
import { BackendUser } from '../../../api/auth/BackendAuth';

import { UserCache } from '../../../main/users/UserCache';

import { AuthPath } from '../../AuthPath';

import { checkClientErrorErrorsStatus } from '../../../utils/parseRequestError/utils/checkClientErrorErrorsStatus';
import { retryAuthFunction } from '../../utils/retryAuthFunction';

const AuthContext = createContext({
  authFetched: false,
  authLoading: false,
  currentUser: null,
  isAuthenticated: false,
  authenticate: (updatedUser: UserInfo) => {
    console.log('error: authenticate should be initialized ', updatedUser);
  },
  deauthenticate: () => {
    console.log('error: deauthenticate should be initialized');
  }
});

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<AuthenticatedUser | null>(
    null
  );
  const [authFetched, setAuthFetched] = useState<boolean>(false);

  const { changeAppLocale } = useChangeAppLocale();

  const router = useRouter();

  const queryClient = useQueryClient();

  const isAuthRoute =
    /^\/auth/.test(router.pathname) || /^\/register/.test(router.pathname);

  const isTermsRoute = /^\/terms/.test(router.pathname);

  const isWithoutCurrentUser =
    (isTermsRoute || isAuthRoute) &&
    !startsWith(router.pathname, AuthPath.inviteTeamMembers()) &&
    !startsWith(router.pathname, AuthPath.businessDescription()) &&
    !startsWith(router.pathname, AuthPath.createProject());

  const { currentUserLoading } =
    useFetchFinCurrentUser<FetchFinCurrentUserQueryResponse>({
      query: FETCH_FIN_CURRENT_USER,
      cacheKey: UserCache.currentUserCacheKey(),
      options: {
        retry: retryAuthFunction,
        enabled: !isWithoutCurrentUser,
        enabledPlaceholder: !isWithoutCurrentUser,
        onSuccess: (data) => {
          if (data?.currentUser) {
            setCurrentUser(new AuthenticatedUser(data.currentUser));
            if (data.currentUser?.locale) {
              changeAppLocale(data.currentUser.locale);
            }
            if (data.currentUser?.nanoId) {
              Sentry.configureScope((scope) => {
                scope.setUser({
                  id: data.currentUser.id,
                  nanoId: data.currentUser.nanoId
                });
              });
            }
          }
          setAuthFetched(true);
        },
        onError: (error) => {
          if (checkClientErrorErrorsStatus(error, { statusCode: 401 })) {
            setCurrentUser(null);
            queryClient.clear();
            if (!isWithoutCurrentUser) {
              router.push(AuthPath.login());
            }
          }
          setAuthFetched(true);
        }
      }
    });

  const authContext = {
    authFetched,
    authLoading: currentUserLoading,
    currentUser,
    isAuthenticated: currentUser?.isAuthenticated(),
    authenticate: useCallback<(updatedUser: UserInfo) => void>(
      (updatedUser) => {
        setAuthFetched(true);
        setCurrentUser(new AuthenticatedUser(updatedUser));
      },
      [setCurrentUser, setAuthFetched]
    ),
    deauthenticate: useCallback<() => void>(() => {
      setAuthFetched(true);
      setCurrentUser(null);
    }, [setCurrentUser, setAuthFetched])
  };

  useAuthRedirects({ currentUser, router });

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}

interface BackendAuthProviderProps {
  backendCurrentUser: BackendUser;
  children: ReactNode;
}
export function BackendAuthProvider({
  backendCurrentUser,
  children
}: BackendAuthProviderProps) {
  const currentUser = new AuthenticatedUser(backendCurrentUser);

  const authContext = {
    authFetched: true,
    authLoading: false,
    currentUser,
    isAuthenticated: currentUser?.isAuthenticated(),
    authenticate: useCallback<(updatedUser: UserInfo) => void>(
      (updatedUser) => {
        //
      },
      []
    ),
    deauthenticate: useCallback<() => void>(() => {
      //
    }, [])
  };

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}

export const useCurrentUser = () => {
  const context = useContext(AuthContext);

  return context.currentUser as AuthenticatedUser;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
